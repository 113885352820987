import { createStore, thunk } from 'easy-peasy'

import PageBuilderModel from './models/PageBuilder.model'
import ScorecardModel from './models/Scorecard.model'
import ScoreTiers from './models/ScoreTiers.model'
import ColorsModel from './models/Colors.model'
import QuizQuestionsModel from './models/QuizQuestions.model'
import QuestionsBuilderModel from './models/QuestionsBuilder.model'
import ThemeModel from './models/Theme.model'

export default createStore(
	{
		activeScorecard: new ScorecardModel().model,
		builder: window.initialData.page ? new PageBuilderModel(window.initialData).model : {},
		scoreTiers: new ScoreTiers().model,
		colors: new ColorsModel().model,
		quiz: {
			questions: new QuizQuestionsModel().model,
			builder: new QuestionsBuilderModel().model,
		},
		signups: {
			data: [],
		},
		gallery: {
			upload: {
				request: thunk(() => {
					throw new Error('Uploading is not available.')
				}),
			},
		},
		theme: new ThemeModel().model,
	},
	{ name: 'BuilderStore' },
)
