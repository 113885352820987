/* eslint-disable react-hooks/rules-of-hooks */
import { useMemo } from 'react'
import { createSelector } from 'reselect'
import PageMetaData from '@v4/utils/data/PageMetaData'
import PageData from '../data/PageData'
import ScorecardSelector from './ScorecardSelector'
import PageTemplateData from '../data/PageTemplateData'
import PageSectionData from '../data/PageSectionData'
import BaseSelector, { hookFromSelector, hookFromSelectorCreator, withStaticSelector } from './BaseSelector'

const hookWithUseMemoParams = hookName => (constructor, propertyName, descriptor) => {
	hookFromSelectorCreator(hookName)(constructor, propertyName, descriptor)

	const existedHook = constructor[hookName]

	Object.defineProperty(constructor, hookName, {
		value(params) {
			const memoParams = constructor.useMemoParams(params)

			return existedHook(params.sectionId, memoParams)
		},
	})
}

class PageBuilderSelector extends BaseSelector {
	static fromStoreState(store) {
		return new this(store.builder)
	}

	static selectParams = (storeState, sectionId, params) => params

	static useMemoParams = ({ group, categoryId, index, name, tierId, itemKey }) => {
		return useMemo(() => ({ group, categoryId, index, name, tierId, itemKey }), [
			categoryId,
			group,
			index,
			itemKey,
			name,
			tierId,
		])
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): boolean} selectLoading
	 * @property {function(): boolean} useLoading
	 */
	@hookFromSelector('useLoading')
	@withStaticSelector
	selectLoading() {
		return this.state.loading
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): boolean} selectUpdating
	 * @property {function(): boolean} useUpdating
	 */
	@hookFromSelector('useUpdating')
	@withStaticSelector
	selectUpdating() {
		return this.state.updating
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): boolean} selectError
	 * @property {function(): boolean} useError
	 */
	@hookFromSelector('useError')
	@withStaticSelector
	selectError() {
		return this.state.error
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): Object} selectInitialValues
	 * @property {function(): Object} useInitialValues
	 */
	@hookFromSelector('useInitialValues')
	@withStaticSelector
	selectInitialValues() {
		return this.state.initial
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): number} selectRequestPageId
	 * @property {function(): number} useRequestPageId
	 */
	@hookFromSelector('useRequestPageId')
	@withStaticSelector
	selectRequestPageId() {
		return this.state.pageId
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): Object} selectPage
	 * @property {function(): Object} usePage
	 */
	@hookFromSelector('usePage')
	@withStaticSelector
	selectPage() {
		return this.state.data
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): number} selectPageId
	 * @property {function(): number} usePageId
	 */
	@hookFromSelector('usePageId')
	@withStaticSelector
	selectPageId() {
		return this.selectPage().id
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): number} selectType
	 * @property {function(): number} useType
	 */
	@hookFromSelector('useType')
	@withStaticSelector
	selectType() {
		return this.selectPage().type
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): string} selectPageSlug
	 * @property {function(): string} usePageSlug
	 */
	@hookFromSelector('usePageSlug')
	@withStaticSelector
	selectPageSlug() {
		return this.selectPage().slug
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): string} selectPageCustomCss
	 * @property {function(): string} usePageCustomCss
	 */
	@hookFromSelector('usePageCustomCss')
	@withStaticSelector
	selectPageCustomCss() {
		return new PageData(this.selectPage()).customCss
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): string} selectPageHeadScript
	 * @property {function(): string} usePageHeadScript
	 */
	@hookFromSelector('usePageHeadScript')
	@withStaticSelector
	selectPageHeadScript() {
		return new PageData(this.selectPage()).customHeadScript
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): string} selectPageBodyScript
	 * @property {function(): string} usePageBodyScript
	 */
	@hookFromSelector('usePageBodyScript')
	@withStaticSelector
	selectPageBodyScript() {
		return new PageData(this.selectPage()).customBodyScript
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): number} selectThemeSettings
	 * @property {function(): number} useThemeSettings
	 */
	@hookFromSelector('useThemeSettings')
	@withStaticSelector
	selectThemeSettings() {
		return this.state.themeSettings
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): string|null} selectActiveGroup
	 * @property {function(): string|null} useActiveGroup
	 */
	@hookFromSelector('useActiveGroup')
	@withStaticSelector
	selectActiveGroup() {
		return this.state.activeGroup
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(Object): Object} selectInitialPage
	 * @property {function(): Object} useInitialPage
	 */
	@hookFromSelector('useInitialPage')
	@withStaticSelector
	selectInitialPage() {
		return this.selectInitialValues().data
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(Object): Object} selectInitialSections
	 * @property {function(): Object} useInitialSections
	 */
	@hookFromSelector('useInitialSections')
	@withStaticSelector
	selectInitialSections() {
		return this.selectInitialValues().sections
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(Object): Object} selectInitialThemeSettings
	 * @property {function(): Object} useInitialThemeSettings
	 */
	@hookFromSelector('useInitialThemeSettings')
	@withStaticSelector
	selectInitialThemeSettings() {
		return this.selectInitialValues().themeSettings
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): string} selectSidebarMode
	 * @property {function(): string} useSidebarMode
	 */
	@hookFromSelector('useSidebarMode')
	@withStaticSelector
	selectSidebarMode() {
		return this.state.sidebarMode
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): string|null} selectActionPending
	 * @property {function(): string|null} useActionPending
	 */
	@hookFromSelector('useActionPending')
	@withStaticSelector
	selectActionPending() {
		return this.state.actionPending
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): Object} selectShortcuts
	 * @property {function(): Object} useShortcuts
	 */
	@hookFromSelector('useShortcuts')
	@withStaticSelector
	selectShortcuts() {
		return this.state.shortcuts
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): Object|null} selectGallery
	 * @property {function(): Object|null} useGallery
	 */
	@hookFromSelector('useGallery')
	@withStaticSelector
	selectGallery() {
		return this.state.gallery
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): boolean} selectIsGalleryActive
	 * @property {function(): boolean} useIsGalleryActive
	 */
	@hookFromSelector('useIsGalleryActive')
	@withStaticSelector
	selectIsGalleryActive() {
		return !!this.selectGallery()
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): Object} selectMinimize
	 * @property {function(): Object} useMinimize
	 */
	@hookFromSelector('useMinimize')
	@withStaticSelector
	selectMinimize() {
		return this.state.minimize
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): Object|null} selectFocusedField
	 * @property {function(): Object|null} useFocusedField
	 */
	@hookFromSelector('useFocusedField')
	@withStaticSelector
	selectFocusedField() {
		return this.state.focusedField
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): string|number|null} selectActiveSectionId
	 * @property {function(): string|number|null} useActiveSectionId
	 */
	@hookFromSelector('useActiveSectionId')
	@withStaticSelector
	selectActiveSectionId() {
		return this.state.activeSectionId
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(sectionId: string|number): PageSectionData} useSectionData
	 */
	@hookFromSelector('useSectionData')
	static useSectionData = sectionId => {
		const section = this.useSection(sectionId)
		// eslint-disable-next-line react-hooks/rules-of-hooks
		return useMemo(() => new PageSectionData(section), [section])
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): Object} selectTemplate
	 * @property {function(): Object} useTemplate
	 */
	@hookFromSelector('useTemplate')
	@withStaticSelector
	selectTemplate() {
		return this.state.template
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): string} selectVersion
	 * @property {function(): string} useVersion
	 */
	@hookFromSelector('useVersion')
	@withStaticSelector
	selectVersion() {
		return this.selectTemplate().version
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): Object} selectThemeTemplate
	 * @property {function(): Object} useThemeTemplate
	 */
	@hookFromSelector('useThemeTemplate')
	@withStaticSelector
	selectThemeTemplate() {
		return this.selectTemplate().theme_settings
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): Object} selectTemplateSections
	 * @property {function(): Object} useTemplateSections
	 */
	@hookFromSelector('useTemplateSections')
	@withStaticSelector
	selectTemplateSections() {
		return this.selectTemplate().sections
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): Object} selectTemplateSectionGroups
	 * @property {function(): Object} useTemplateSectionGroups
	 */
	@hookFromSelector('useTemplateSectionGroups')
	@withStaticSelector
	selectTemplateSectionGroups() {
		return this.selectTemplate().section_groups
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object, group: string): Object} selectTemplateSectionGroup
	 * @property {function(group: string): Object} useTemplateSectionGroup
	 * */
	@hookFromSelector('useTemplateSectionGroup')
	@withStaticSelector
	selectTemplateSectionGroup(group) {
		return this.selectTemplateSectionGroups()?.[group]
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object, sectionId: string|number): Object} selectSectionMetaMap
	 * @property {function(sectionId: string|number): Object} useSectionMetaMap
	 * */
	@hookFromSelector('useSectionMetaMap')
	@withStaticSelector
	selectSectionMetaMap(sectionId) {
		return sectionId
			? _.get(this.state, ['sections', 'entities', sectionId, 'meta'].join('.'))
			: _.get(this.state, 'themeSettings')
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object, sectionKey: string): Object} selectSectionTemplate
	 * @property {function(sectionKey: string): Object} useSectionTemplate
	 */
	@hookFromSelector('useSectionTemplate')
	@withStaticSelector
	selectSectionTemplate(sectionKey) {
		if (!sectionKey) return null

		switch (this.selectVersion()) {
			case PageData.VERSIONS.V4: {
				const [group, key] = sectionKey.split('/')
				return this.selectTemplateSectionGroup(group)?.sections[key]
			}
			case PageData.VERSIONS.V3:
			case PageData.VERSIONS.V2:
			case PageData.VERSIONS.LEGACY:
				return this.selectTemplateSections()[sectionKey]
			default:
				return null
		}
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object, sectionKey: string): Object} selectTemplateFieldGroups
	 * @property {function(sectionKey: string): Object} useTemplateFieldGroups
	 * */
	@hookFromSelector('useTemplateFieldGroups')
	@withStaticSelector
	selectTemplateFieldGroups(sectionKey) {
		if (!sectionKey) {
			return this.selectTemplate().theme_settings
		}

		return this.selectSectionTemplate(sectionKey)?.fieldgroups
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object, sectionKey?: string): Object} selectTemplateFields
	 * @property {function(sectionKey?: string): Object} useTemplateFields
	 */
	@hookFromSelector('useTemplateFields')
	@withStaticSelector
	selectTemplateFields(sectionKey) {
		return _.get(this.selectTemplateFieldGroups(sectionKey), 'fields')
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object, sectionKey: string, name: string): Object} selectFieldConfig
	 * @property {function(sectionKey: string, name: string): Object} useFieldConfig
	 */
	@hookFromSelector('useFieldConfig')
	@withStaticSelector
	selectFieldConfig(sectionKey, name) {
		return _.get(this.selectTemplateFields(sectionKey), name)
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object, sectionKey: string): Object} selectTemplateGroups
	 * @property {function(sectionKey: string): Object} useTemplateGroups
	 * */
	@hookFromSelector('useTemplateGroups')
	@withStaticSelector
	selectTemplateGroups(sectionKey) {
		return _.get(this.selectTemplateFieldGroups(sectionKey), 'groups')
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object, sectionKey: string, group: string): Object} selectTemplateGroupConfig
	 * @property {function(sectionKey: string, group: string): Object} useTemplateGroupConfig
	 * */
	@hookFromSelector('useTemplateGroupConfig')
	@withStaticSelector
	selectTemplateGroupConfig(sectionKey, group) {
		return _.get(this.selectTemplateGroups(sectionKey), group)
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): array} selectAudiences
	 * @property {function(): array} useAudiences
	 * */
	@hookFromSelector('useAudiences')
	@withStaticSelector
	selectAudiences() {
		return this.state.audiences
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): Object} selectSections
	 * @property {function(): Object} useSections
	 * */
	@hookFromSelector('useSections')
	@withStaticSelector
	selectSections() {
		return this.state.sections
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): Object} selectSectionCollections
	 * @property {function(): Object} useSectionCollections
	 * */
	@hookFromSelector('useSectionCollections')
	@withStaticSelector
	selectSectionCollections() {
		return this.selectSections().collections
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object, sortGroup: string): Object} selectSectionGroupIds
	 * @property {function(sortGroup: string): Object} useSectionGroupIds
	 * */
	@hookFromSelector('useSectionGroupIds')
	@withStaticSelector
	selectSectionGroupIds(sortGroup) {
		return this.selectSectionCollections()?.[sortGroup]
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): Object} selectSectionEntities
	 * @property {function(): Object} useSectionEntities
	 * */
	@hookFromSelector('useSectionEntities')
	@withStaticSelector
	selectSectionEntities() {
		return this.selectSections().entities
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): ?Object} selectActiveSection
	 * @property {function(): ?Object} useActiveSection
	 * */
	@hookFromSelector('useActiveSection')
	@withStaticSelector
	selectActiveSection() {
		const entities = this.selectSectionEntities()

		return entities && entities[this.selectActiveSectionId()]
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): ?string} selectActiveSectionKey
	 * @property {function(): ?string} useActiveSectionKey
	 * */
	@hookFromSelector('useActiveSectionKey')
	@withStaticSelector
	selectActiveSectionKey() {
		const section = this.selectActiveSection()

		return section && section.key
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object, sectionId: string|number): Object} selectSection
	 * @property {function(sectionId: string|number): Object} useSection
	 * */
	@hookFromSelector('useSection')
	@withStaticSelector
	selectSection(sectionId) {
		return this.selectSectionEntities()[sectionId]
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object, sectionId?: string|number): Object} selectMetaMap
	 * @property {function(sectionId?: string|number): Object} useMetaMap
	 * */
	@hookFromSelector('useMetaMap')
	@withStaticSelector
	selectMetaMap(sectionId) {
		if (sectionId) {
			const section = this.selectSection(sectionId)

			return section?.meta
		}
		return this.selectThemeSettings()
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object, sectionId: string|number): ?string} selectSectionKey
	 * @property {function(sectionId: string|number): Object} useSectionKey
	 * */
	@hookFromSelector('useSectionKey')
	@withStaticSelector
	selectSectionKey(sectionId) {
		if (sectionId) {
			const section = this.selectSection(sectionId)

			return section?.key
		}
		return undefined
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object, sectionId: string|number): ?number} selectSectionCategoryId
	 * @property {function(sectionId: string|number): ?number} useSectionCategoryId
	 * */
	@hookFromSelector('useSectionCategoryId')
	@withStaticSelector
	selectSectionCategoryId(sectionId) {
		if (sectionId) {
			const section = this.selectSection(sectionId)

			return section?.category_id
		}
		return undefined
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(condition: string): boolean} useIsSectionHidden
	 */
	@hookFromSelectorCreator('useIsSectionHidden')
	static createSelectIsSectionHidden = () =>
		createSelector([(state, condition) => condition, ScorecardSelector.selectScorecard], (condition, scorecard) =>
			PageTemplateData.isHiddenOnCondition(condition, scorecard),
		)

	/**
	 * @class PageBuilderSelector
	 * @property {function(sortGroup: string): array} useVisibleSectionGroupIds
	 */
	@hookFromSelectorCreator('useVisibleSectionGroupIds')
	static createSelectVisibleSectionGroupIds = () =>
		createSelector(
			[this.selectSectionGroupIds, this.selectSectionEntities],
			(ids, entities) =>
				_.reduce(
					ids,
					(acc, sectionId, index) => {
						const sectionData = new PageSectionData(entities[sectionId])

						if (!sectionData.isEmpty && !sectionData.isHidden) {
							acc.push({
								id: sectionId,
								index,
							})
						}

						return acc
					},
					[],
				),
			{
				memoizeOptions: {
					resultEqualityCheck: _.isEqual,
				},
			},
		)

	/**
	 * @class PageBuilderSelector
	 * @property {function(sortGroup: string, sectionId: string|number): number} useSectionIndex
	 */
	@hookFromSelectorCreator('useSectionIndex')
	static createSelectSectionIndex = () =>
		createSelector(
			[(state, sortGroup, sectionId) => sectionId, this.selectSectionGroupIds],
			(sectionId, ids) => _.indexOf(ids, sectionId) + 1,
		)

	/**
	 * @class PageBuilderSelector
	 * @property {function(scrolling: string): Object|null} useFocusedFieldByScrolling
	 */
	@hookFromSelectorCreator('useFocusedFieldByScrolling')
	static createSelectFocusedFieldByScrolling = () =>
		createSelector(
			[(state, scrolling) => scrolling, PageBuilderSelector.selectFocusedField],
			(scrolling, focusedField) => (focusedField && focusedField.scrolling === scrolling ? focusedField : null),
		)

	/**
	 * @class PageBuilderSelector
	 * @property {function({ sectionKey: string }): ?Object} useFieldGroups
	 */
	@hookFromSelectorCreator('useFieldGroups')
	static createSelectFieldGroups = () =>
		createSelector(
			[(state, sectionKey) => sectionKey, PageBuilderSelector.selectTemplate],
			(sectionKey, template) => {
				const instance = new this({ template })

				if (sectionKey) {
					return instance.selectSectionTemplate(sectionKey)?.fieldgroups
				}

				return instance.selectThemeTemplate()
			},
		)

	/**
	 * @class PageBuilderSelector
	 * @property {function({ sectionId: string|number }): ?Object} useSectionCategory
	 */
	@hookFromSelectorCreator('useSectionCategory')
	static createSelectSectionCategory = () =>
		createSelector([this.selectSectionCategoryId, state => state.categories.data], (categoryId, categories) => {
			return categoryId && _.find(categories, { id: categoryId })
		})

	selectMetaGroup(sectionId, { group }) {
		return _.get(this.selectMetaMap(sectionId), group)
	}

	static createSelectMetaGroup = () =>
		createSelector([PageBuilderSelector.selectMetaMap, this.selectParams], (sectionMeta, { group }) =>
			_.get(sectionMeta, group),
		)

	selectMetaActiveItemKey(sectionId, params) {
		return _.get(this.selectMetaGroup(sectionId, params), 'activeItemKey')
	}

	@hookWithUseMemoParams('useMetaActiveItemKey')
	static createSelectMetaActiveItemKey = () =>
		createSelector([this.createSelectMetaGroup()], group => _.get(group, 'activeItemKey'))

	/**
	 * @class PageBuilderSelector
	 * @property {function(params: Object): boolean} useIsMetaItemActive
	 */
	@hookWithUseMemoParams('useIsMetaItemActive')
	static createSelectIsMetaItemActive = () =>
		createSelector(
			[this.createSelectMetaActiveItemKey(), this.selectParams],
			(activeItemKey, { itemKey }) => !!activeItemKey && activeItemKey === itemKey,
		)

	selectMetaActiveCategoryId(sectionId, params) {
		return _.get(this.selectMetaGroup(sectionId, params), 'activeCategoryId')
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(params: Object): number|null} useMetaActiveCategoryId
	 */
	@hookWithUseMemoParams('useMetaActiveCategoryId')
	static createSelectMetaActiveCategoryId = () =>
		createSelector([this.createSelectMetaGroup()], group => _.get(group, 'activeCategoryId'))

	/**
	 * @class PageBuilderSelector
	 * @property {function(params: Object): boolean} useIsMetaCategoryActive
	 */
	@hookWithUseMemoParams('useIsMetaCategoryActive')
	static createSelectIsMetaCategoryActive = () =>
		createSelector(
			[this.createSelectMetaActiveCategoryId(), this.selectParams],
			(activeCategoryId, { categoryId }) => !!activeCategoryId && activeCategoryId === categoryId,
		)

	selectMetaList(sectionId, params) {
		return _.get(this.selectMetaGroup(sectionId, params), 'list')
	}

	static createSelectMetaList = () => createSelector([this.createSelectMetaGroup()], group => _.get(group, 'list'))

	selectMetaItem(sectionId, { group, index, categoryId }) {
		return _.get(this.selectMetaList(sectionId, { group }), categoryId ?? index ?? 0)
	}

	static createSelectMetaItem = () =>
		createSelector([this.createSelectMetaList(), this.selectParams], (list, { index, categoryId }) =>
			_.get(list, categoryId ?? index ?? 0),
		)

	selectMetaItemKey(sectionId, params) {
		return _.get(this.selectMetaItem(sectionId, params), 'key')
	}

	static createSelectMetaItemKey = () => createSelector([this.createSelectMetaItem()], item => _.get(item, 'key'))

	selectMetaItemActiveTierId(sectionId, params) {
		return _.get(this.selectMetaItem(sectionId, params), 'activeTierId')
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(params: Object): ?number} useMetaItemActiveTierId
	 */
	@hookWithUseMemoParams('useMetaItemActiveTierId')
	static createSelectMetaItemActiveTierId = () =>
		createSelector([this.createSelectMetaItem()], item => _.get(item, 'activeTierId'))

	selectMetaItemFields(sectionId, params) {
		return _.get(this.selectMetaItem(sectionId, params), 'fields')
	}

	static createSelectMetaItemFields = () =>
		createSelector([this.createSelectMetaItem()], item => _.get(item, 'fields'))

	selectMetaField(sectionId, params) {
		return _.get(this.selectMetaItemFields(sectionId, params), params.name)
	}

	static createSelectMetaField = () =>
		createSelector([this.createSelectMetaItemFields(), this.selectParams], (fields, params) =>
			_.get(fields, params.name),
		)

	selectMeta(sectionId, params) {
		return _.get(this.selectMetaField(sectionId, params), params.tierId || 'default')
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(params: Object): ?Object} useMeta
	 */
	@hookWithUseMemoParams('useMeta')
	static createSelectMeta = () =>
		createSelector([this.createSelectMetaField(), this.selectParams], (field, params) =>
			_.get(field, params.tierId || 'default'),
		)

	/**
	 * @class PageBuilderSelector
	 * @property {function(params: Object): array} useRepeatableGroupKeys
	 */
	@hookWithUseMemoParams('useRepeatableGroupKeys')
	static createSelectRepeatableGroupKeys = () =>
		createSelector([this.createSelectMetaList()], list => _.map(list, ({ key }) => key), {
			memoizeOptions: { resultEqualityCheck: _.isEqual },
		})

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): Object} selectCategoryScores
	 * @property {function(): Object} useCategoryScores
	 * */
	@hookFromSelector('useCategoryScores')
	@withStaticSelector
	selectCategoryScores() {
		return this.state.categoryScores
	}

	@hookFromSelector('useIsCollectionEmpty')
	@withStaticSelector
	selectIsCollectionEmpty(collection) {
		return _.isEmpty(this.selectSectionCollections()[collection])
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(params: Object): Object} useCategoryTierIds
	 */
	@hookWithUseMemoParams('useCategoryTierIds')
	static createSelectCategoryTierIds = () =>
		createSelector(
			[this.createSelectMetaList()],
			list =>
				_.reduce(
					list,
					(acc, { activeTierId }, categoryId) => {
						acc[categoryId] = activeTierId
						return acc
					},
					{},
				),
			{
				memoizeOptions: { resultEqualityCheck: _.isEqual },
			},
		)

	static useMetaData = ({ name, sectionId, index, tierId, categoryId, repeatable }) => {
		const sectionKey = PageBuilderSelector.useSectionKey(sectionId)
		const fieldGroups = PageBuilderSelector.useFieldGroups(sectionKey)
		const config = fieldGroups?.fields[name]
		const group = config?.group
		const meta = PageBuilderSelector.useMeta({ group, categoryId, index, name, sectionId, tierId })
		const scorecard = ScorecardSelector.useScorecard()

		return useMemo(() => {
			const metaData = new PageMetaData(meta, config, scorecard)
			metaData.fill({
				landing_page_section_id: sectionId ?? null,
				score_tier_id: tierId ?? null,
				category_id: categoryId ?? null,
				meta_key: name,
				meta_key_index: index ?? null,
				repeatable_group: repeatable ? config?.group : null,
			})

			return metaData
		}, [meta, config, scorecard, sectionId, tierId, categoryId, name, index, repeatable])
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(group: string, key: string): boolean} useMinimize
	 */
	@hookFromSelectorCreator('useIsMinimized')
	static createSelectIsMinimized = () =>
		createSelector(
			[
				PageBuilderSelector.selectMinimize,
				(state, group) => group,
				(state, group, key) => key,
				(state, group, key, defaultValue) => defaultValue,
			],
			(minimize, group, key, defaultValue = true) => _.get(minimize, [group, key].join('.'), defaultValue),
		)

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): string|null} selectActivePageType
	 * @property {function(): string|null} useActivePageType
	 */
	@hookFromSelector('useActivePageType')
	@withStaticSelector
	selectActivePageType() {
		return this.state.activePageType
	}

	/**
	 * @class PageBuilderSelector
	 * @property {function(storeState: Object): boolean} selectIsQuestionsMode
	 * @property {function(): boolean} useIsQuestionsMode
	 */
	@hookFromSelector('useIsQuestionsMode')
	@withStaticSelector
	selectIsQuestionsMode() {
		return new PageData(this.selectPage()).isTypeQuestions && this.selectSidebarMode() !== 'SECTIONS'
	}
}

/**
 * @class PageBuilderSelector
 * @property {function(storeState: Object, validator: function): Object} selectValidationErrors
 * @property {function(validator: function): Object} useValidationErrors
 */
PageBuilderSelector.addSelector(
	'selectValidationErrors',
	createSelector([(state, validator) => validator, PageBuilderSelector.selectPage], (validator, data) =>
		validator(data),
	),
)

/**
 * @class PageBuilderSelector
 * @property {function(storeState: Object, validator: function): boolean} selectHasValidationErrors
 * @property {function(validator: function): boolean} useHasValidationErrors
 */
PageBuilderSelector.addSelector(
	'selectHasValidationErrors',
	createSelector([PageBuilderSelector.selectValidationErrors], errors => _.some(errors, value => value)),
)

/**
 * @class PageBuilderSelector
 * @property {function(storeState: Object): boolean} selectIsAdditionSectionAvailable
 * @property {function(): boolean} useIsAdditionSectionAvailable
 */
PageBuilderSelector.addSelector(
	'selectIsAdditionSectionAvailable',
	createSelector(
		[ScorecardSelector.selectIsSectionsLocked, PageBuilderSelector.selectVersion, PageBuilderSelector.selectType],
		(isSectionsLocked, version, pageType) =>
			!!version && !isSectionsLocked && (PageData.isVersionV4(version) || !PageData.isTypeQuestions(pageType)),
	),
)

/**
 * @class PageBuilderSelector
 * @property {function(storeState: Object): string} selectPreviewUrl
 * @property {function(): string} usePreviewUrl
 */
PageBuilderSelector.addSelector(
	'selectPreviewUrl',
	createSelector([PageBuilderSelector.selectPage], page => new PageData(page).previewUrl),
)

/**
 * @class PageBuilderSelector
 * @property {function(storeState: Object): boolean} selectIsDataEqual
 * @property {function(): boolean} useIsDataEqual
 */
PageBuilderSelector.addSelector(
	'selectIsDataEqual',
	(() => {
		const selectIsPageEqual = createSelector(
			[PageBuilderSelector.selectPage, PageBuilderSelector.selectInitialPage],
			_.isEqual,
		)

		const selectIsSectionsEqual = createSelector(
			[PageBuilderSelector.selectSections, PageBuilderSelector.selectInitialSections],
			_.isEqual,
		)

		const selectIsThemeSettingsEqual = createSelector(
			[PageBuilderSelector.selectThemeSettings, PageBuilderSelector.selectInitialThemeSettings],
			_.isEqual,
		)

		return createSelector(
			[selectIsPageEqual, selectIsSectionsEqual, selectIsThemeSettingsEqual],
			(dataEqual, sectionsEqual, themeSettingsEqual) => dataEqual && sectionsEqual && themeSettingsEqual,
		)
	})(),
)

/**
 * @class PageBuilderSelector
 * @property {function(storeState: Object): number} selectSectionsCount
 * @property {function(): number} useSectionsCount
 */
PageBuilderSelector.addSelector(
	'selectSectionsCount',
	createSelector([PageBuilderSelector.selectSectionEntities, PageBuilderSelector.selectPage], (sections, page) =>
		_.reduce(
			sections,
			(acc, section) => {
				if (!section) return acc

				if (new PageData(page).isVersionV4) {
					const [group] = section.key.split('/')
					acc.groups[group] = (acc.groups[group] || 0) + 1
				} else {
					acc.sections[section.key] = (acc.sections[section.key] || 0) + 1
				}

				return acc
			},
			{
				groups: {},
				sections: {},
			},
		),
	),
)

/**
 * @class PageBuilderSelector
 * @property {function(storeState: Object): array} selectAvailableGroups
 * @property {function(): array} useAvailableGroups
 */
PageBuilderSelector.addSelector(
	'selectAvailableGroups',
	createSelector(
		[PageBuilderSelector.selectSectionsCount, PageBuilderSelector.selectTemplate],
		(counts, { section_groups: groups }) =>
			_.reduce(
				groups,
				(acc, group, key) => {
					if (group.repeatable || !counts.groups[key]) {
						acc.push({
							key,
							group,
						})
					}

					return acc
				},
				[],
			),
	),
)

/**
 * @class PageBuilderSelector
 * @property {function(storeState: Object): array} selectAvailableSections
 * @property {function(): array} useAvailableSections
 */
PageBuilderSelector.addSelector(
	'selectAvailableSections',
	createSelector(
		[PageBuilderSelector.selectSectionsCount, PageBuilderSelector.selectTemplate],
		(initialCounts, { version, section_groups: groups, sections: initialSections }) => {
			const getAvailableSections = (sections, counts, group, acc = []) =>
				_.reduce(
					sections,
					(sectionsAcc, section, key) => {
						const sectionKey = group ? `${group}/${key}` : key
						if (section.repeatable || !counts[section.sections ? 'groups' : 'sections'][sectionKey]) {
							if (section.sections) {
								return getAvailableSections(section.sections, counts, key, sectionsAcc)
							}
							sectionsAcc.push({
								group,
								key,
								section,
							})
						}

						return sectionsAcc
					},
					acc,
				)

			return getAvailableSections(PageData.isVersionV4(version) ? groups : initialSections, initialCounts)
		},
	),
)

/**
 * @class PageBuilderSelector
 * @property {function(storeState: Object): boolean} selectHasAvailableSections
 * @property {function(): boolean} useHasAvailableSections
 */
PageBuilderSelector.addSelector(
	'selectHasAvailableSections',
	createSelector(PageBuilderSelector.selectAvailableSections, sections => !!sections.length),
)

/**
 * @class PageBuilderSelector
 * @property {function(storeState: Object): Object|null} selectActiveGroupItem
 * @property {function(): Object|null} useActiveGroupItem
 */
PageBuilderSelector.addSelector(
	'selectActiveGroupItem',
	createSelector(
		[
			PageBuilderSelector.selectActiveGroup,
			PageBuilderSelector.selectAvailableGroups,
			PageBuilderSelector.selectType,
		],
		(activeGroup, availableGroups, pageType) => {
			const groupKeys = availableGroups.map(({ key }) => key)
			if (!activeGroup || !_.includes(groupKeys, activeGroup)) {
				return _.reduce(
					PageData.getMenuList(pageType),
					(acc, item) => {
						if (acc) return acc

						if (typeof item === 'object') {
							return item.child
								? item.child.find(key => groupKeys.includes(key))
								: groupKeys.includes(item.key)
						}

						return (groupKeys.includes(item) && item) || null
					},
					null,
				)
			}

			return activeGroup
		},
	),
)

/**
 * @class PageBuilderSelector
 * @property {function(storeState: Object, variant: string): ?string|number} selectPopupSectionId
 * @property {function(variant: string): ?string|number} usePopupSectionId
 */
PageBuilderSelector.addSelector(
	'selectPopupSectionId',
	createSelector(
		[
			(state, variant) => variant,
			PageBuilderSelector.selectTemplateSectionGroups,
			PageBuilderSelector.selectSectionEntities,
		],
		(variant, templateGroups, entities) => {
			const group = PageTemplateData.SECTION_TYPES.POPUP
			const templateKey = _.findKey(templateGroups?.[group].sections, ({ theme }) => theme?.variant === variant)

			const section = templateKey && _.find(entities, ({ key }) => key === `${group}/${templateKey}`)

			return section && (section.temporaryId || section.id)
		},
	),
)

export default PageBuilderSelector
