import React, { Suspense } from 'react'
import Fallback from './components/Fallback'

const withLazy = Component =>
	function LazyComponent(props) {
		return (
			<Suspense fallback={<Fallback />}>
				<Component {...props} />
			</Suspense>
		)
	}

export default withLazy
