import HttpBackend from 'i18next-http-backend'
import resourcesToBackend from 'i18next-resources-to-backend'
import SCORECARD_TRANSLATION from '@v4/../../../public/locales/en/scorecard.json'
import LEAD_TRANSLATION from '@v4/../../../public/locales/en/lead.json'
import SCORE_TRANSLATION from '@v4/../../../public/locales/en/score.json'

const VERSION = '1.1.2'

export const I18N_CONFIG = {
	fallbackLng: 'en',
	load: 'currentOnly',
	ns: ['scorecard', 'lead', 'score', 'countries', 'industries'],
	defaultNS: 'scorecard',
	interpolation: {
		escapeValue: false,
	},
	backend: {
		backends: [
			HttpBackend,
			resourcesToBackend({
				en: {
					scorecard: SCORECARD_TRANSLATION,
					lead: LEAD_TRANSLATION,
					score: SCORE_TRANSLATION,
				},
			}),
		],
		backendOptions: [
			{
				loadPath: `${import.meta.env.VITE_ASSET_URL}/locales/{{lng}}/{{ns}}.json`,
				queryStringParams: { v: VERSION },
				crossDomain: true,
			},
		],
	},
	react: {
		bindI18n: 'languageChanged',
		useSuspense: false,
	},
}
