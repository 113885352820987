import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { I18nextProvider as OriginI18nextProvider } from 'react-i18next'
import { useI18nextProvider } from './I18nextProvider.hooks'

const I18nextProvider = props => {
	const [{ children, i18next }] = useI18nextProvider(props)

	return <OriginI18nextProvider i18n={i18next}>{children}</OriginI18nextProvider>
}

I18nextProvider.defaultProps = {
	children: PropTypes.node,
}

export default memo(I18nextProvider)
