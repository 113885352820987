import BaseData from './BaseData'

const STATUSES = Object.freeze({
	DRAFT_MODE: 0,
	COMPLETE: 1,
	CLONING: 2,
	TEMPLATE: 3,
})

const FORBID_TEMPLATE_ACTIONS = Object.freeze({
	QUESTIONS_CATEGORIES: 0b00001,
	COOKIE_POLICY: 0b00010,
	PAGES_CREATE_DELETE: 0b00100,
	PAGE_SECTION: 0b01000,
	SCORE_TIERS: 0b10000,
})

const SIGNUP_LOCATION = Object.freeze({
	AFTER_QUESTIONS: 1,
	BEFORE_QUESTIONS: 0,
})

class ScorecardData extends BaseData {
	/**
	 * @return {Readonly<{COMPLETE: number, DRAFT_MODE: number, CLONING: number, TEMPLATE: number}>}
	 */
	static get STATUSES() {
		return STATUSES
	}

	/**
	 * @return {Readonly<{
	 * QUESTIONS_CATEGORIES: number,
	 * PAGES_CREATE_DELETE: number,
	 * COOKIE_POLICY: number,
	 * PAGE_SECTION: number,
	 * SCORE_TIERS: number
	 * }>}
	 */
	static get FORBID_TEMPLATE_ACTIONS() {
		return FORBID_TEMPLATE_ACTIONS
	}

	/**
	 * @return {Readonly<{AFTER_QUESTIONS: number, BEFORE_QUESTIONS: number}>}
	 */
	static get SIGNUP_LOCATION() {
		return SIGNUP_LOCATION
	}

	get status() {
		return this.data.status
	}

	get settings() {
		return this.data.settings
	}

	get singUpLocation() {
		return this.settings?.sign_up_location
	}

	static isStatusDraftMode = status => {
		return status === this.STATUSES.DRAFT_MODE
	}

	get isStatusDraftMode() {
		return this.constructor.isStatusDraftMode(this.status)
	}

	static isStatusComplete = status => {
		return status === this.STATUSES.COMPLETE
	}

	get isStatusComplete() {
		return this.constructor.isStatusComplete(this.status)
	}

	static isStatusCloning = status => {
		return status === this.STATUSES.CLONING
	}

	get isStatusCloning() {
		return this.constructor.isStatusCloning(this.status)
	}

	static isStatusTemplate = status => {
		return status === this.STATUSES.TEMPLATE
	}

	get templateAccessSettings() {
		return this.data.template_access_settings
	}

	static isCookieLocked = accessSettings => {
		return !!(accessSettings & this.FORBID_TEMPLATE_ACTIONS.COOKIE_POLICY)
	}

	get isCookieLocked() {
		return this.constructor.isCookieLocked(this.templateAccessSettings)
	}

	static isQuestionsLocked = accessSettings => {
		return !!(accessSettings & this.FORBID_TEMPLATE_ACTIONS.QUESTIONS_CATEGORIES)
	}

	get isQuestionsLocked() {
		return this.constructor.isQuestionsLocked(this.templateAccessSettings)
	}

	static isPagesLocked = accessSettings => {
		return !!(accessSettings & this.FORBID_TEMPLATE_ACTIONS.PAGES_CREATE_DELETE)
	}

	get isPagesLocked() {
		return this.constructor.isPagesLocked(this.templateAccessSettings)
	}

	static isSectionsLocked = accessSettings => {
		return !!(accessSettings & this.FORBID_TEMPLATE_ACTIONS.PAGE_SECTION)
	}

	get isSectionsLocked() {
		return this.constructor.isSectionsLocked(this.templateAccessSettings)
	}

	static isTiersLocked = accessSettings => {
		return !!(accessSettings & this.FORBID_TEMPLATE_ACTIONS.SCORE_TIERS)
	}

	get isTiersLocked() {
		return this.constructor.isTiersLocked(this.templateAccessSettings)
	}

	static isSignUpAfterQuestion = signUpLocation => {
		return signUpLocation === this.SIGNUP_LOCATION.AFTER_QUESTIONS
	}

	get isSignUpAfterQuestion() {
		return this.constructor.isSignUpAfterQuestion(this.singUpLocation)
	}

	static isSignUpBeforeQuestion = signUpLocation => {
		return signUpLocation === this.SIGNUP_LOCATION.BEFORE_QUESTIONS
	}

	get isSignUpBeforeQuestion() {
		return this.constructor.isSignUpBeforeQuestion(this.singUpLocation)
	}
}

export default ScorecardData
