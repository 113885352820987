import { actionOn } from 'easy-peasy'

export default {
	data: window.initialData.shortcuts || {},
	onSuccessSingUp: actionOn(
		(actions, storeActions) => [
			storeActions.signUp.completeSingUp.successType,
			storeActions.signUp.updateSingUp.successType,
		],
		(state, { result: { shortcuts } }) => {
			state.data = shortcuts
		},
	),
}
