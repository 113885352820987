/* eslint-disable class-methods-use-this */
import BaseModel, { Action, Thunk } from '@v4/utils/model/BaseModel'

const MAIN_TAB = Object.freeze({
	QUESTIONS: 'questions',
	CATEGORIES: 'categories',
})

const QUESTION_TAB = Object.freeze({
	SETTINGS: 'settings',
	LOGIC: 'logic',
})

class QuestionsBuilderModel extends BaseModel {
	/**
	 * @returns {Readonly<{QUESTIONS: string, CATEGORIES: string}>}
	 */
	static get MAIN_TAB() {
		return MAIN_TAB
	}

	/**
	 * @returns {Readonly<{SETTINGS: string, LOGIC: string}>}
	 */
	static get QUESTION_TAB() {
		return QUESTION_TAB
	}

	get initialData() {
		return {
			isQuestionsMode: true,
			pageId: null,
			questionTab: null,
			categoryTab: null,
			activeQuestionId: null,
			activeQuestionIndex: null,
			activeCategoryId: null,
			questionAdditionEnabled: false,
			gallery: null,
			activeOptionId: null,
			activeOptionIndex: null,
		}
	}

	@Action
	setScrollingMenu() {}

	@Action
	setQuestionTab() {}

	@Action
	setCategoryTab() {}

	@Action
	setActiveQuestionId() {}

	@Action
	setActiveQuestionIndex() {}

	@Action
	setActiveCategoryId() {}

	@Action
	setQuestionAdditionEnabled() {}

	@Thunk
	copyQuestion() {}

	@Thunk
	createQuestion() {}

	@Thunk
	copyCategory() {}

	@Thunk
	createCategory() {}

	@Action
	setGallery() {}

	@Action
	setActiveOptionId() {}

	@Action
	setActiveOptionIndex() {}

	@Action
	setPageId() {}

	@Thunk
	fetchPage() {}
}
export default QuestionsBuilderModel
