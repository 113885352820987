// eslint-disable-next-line import/prefer-default-export
export const APP_MODE = {
	builder: 'builder',
	scorecard: 'scorecard',
	screenshot: 'screenshot',
}

export const QUERY_PARAMS_TO_DELETE = ['sa_hide_header', 'sa_hide_footer', 'sa_target']

export const BUILDER_VERSION = '4.82'
