import { action, computed } from 'easy-peasy'
import { APP_MODE } from '@v4/constants/general'
import { checkIsBuilderMode, checkIsScorecardMode } from '@v4/utils/general'

export default {
	autoHeight: false,
	mode: window.initialData.mode || APP_MODE.scorecard,
	basename: window.initialData.base_url || '',
	isBuilderMode: computed(state => checkIsBuilderMode(state.mode)),
	isScorecardMode: computed(state => checkIsScorecardMode(state.mode)),
	targetWindow: new URLSearchParams(window.location.search).get('sa_target') || null,
	themeButtonVisible: false,

	setMode: action((state, payload) => {
		state.mode = payload
	}),

	setAutoHeight: action((state, payload) => {
		state.autoHeight = payload
	}),

	setThemeButtonVisible: action((state, payload) => {
		state.themeButtonVisible = payload
	}),
}
