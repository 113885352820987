import BaseSelector, { hookFromSelector, withStaticSelector } from './BaseSelector'

const PERMISSIONS = {
	GLOBAL_MANAGE_LEADS: 'global_manage_leads',
	GLOBAL_MANAGE_SETTINGS: 'global_manage_settings',
	VIEW_NOVA: 'view_nova',
	AUTH_WITHOUT_2FA: 'auth_without_2fa',
	IMPERSONATE: 'impersonate',
	GLOBAL_ACCESS: 'global_access',
	USER_AI_CONTENT: 'user_ai_content',
}

class UserSelector extends BaseSelector {
	static fromStoreState(store) {
		return new this(store.user)
	}

	static hasPermission(permissions, permission) {
		return !permission || _.includes(permissions, permission)
	}

	/**
	 * @class UserSelector
	 * @property {function(storeState: Object): Object|undefined} selectData
	 * @property {function(): Object|undefined} useData
	 */
	@hookFromSelector('useData')
	@withStaticSelector
	selectData() {
		return this.state?.data
	}

	/**
	 * @class UserSelector
	 * @property {function(storeState: Object): number[]|undefined} selectPermissions
	 * @property {function(): number[]|undefined} usePermissions
	 */
	@hookFromSelector('usePermissions')
	@withStaticSelector
	selectPermissions() {
		return this.selectData()?.permissions
	}

	/**
	 * @class UserSelector
	 * @property {function(storeState: Object, permission?: number): boolean} selectHasPermission
	 * @property {function(permission?: number): boolean} useHasPermission
	 */
	@hookFromSelector('useHasPermission')
	@withStaticSelector
	selectHasPermission(permission) {
		return !permission || _.includes(this.selectPermissions(), permission)
	}

	/**
	 * @class UserSelector
	 * @property {function(storeState: Object): number} selectHasAdminPermissions
	 * @property {function(): number} useHasAdminPermissions
	 */
	@hookFromSelector('useHasAdminPermissions')
	@withStaticSelector
	selectHasAdminPermissions() {
		return _.every([PERMISSIONS.GLOBAL_ACCESS, PERMISSIONS.GLOBAL_MANAGE_SETTINGS], permission =>
			this.selectHasPermission(permission),
		)
	}
}

export default UserSelector
