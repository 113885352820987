/* eslint-disable no-param-reassign */
import ScorecardData from './ScorecardData'
import BaseData from './BaseData'

const GROUP_TYPES = Object.freeze({
	TIER: 'tier',
	CATEGORY: 'category',
})

const SORT_GROUPS = Object.freeze({
	POPUP: 'popup',
	HEADER: 'header',
	MAIN: 'main',
	FOOTER: 'footer',
})

const HIDDEN_CONDITIONS = Object.freeze({
	SING_UP_BEFORE: 'sing_up_before',
	SING_UP_AFTER: 'sing_up_after',
	ALWAYS: 'always',
})

const SHORTCUT_LINKS = Object.freeze({
	result_url: { name: 'View Result', url: '{result_url}' },
	report_url: { name: 'View PDF', url: '{report_url}' },
	scorecard_url: { name: 'Scorecard Url', url: '{scorecard_url}' },
	email: { name: 'Email', url: 'mailto:{email}' },
	continue_questions_url: { name: 'Continue questions', url: '{continue_questions_url}' },
})

const SECTION_TYPES = Object.freeze({
	VIDEO: 'video',
	CONTENT: 'content',
	QUESTION_PROGRESS: 'question-progress',
	INDIVIDUAL_CATEGORY: 'individual-category',
	HTML_BLOCK: 'html-block',
	BANNER: 'banner',
	FORM: 'form',
	FOOTER: 'footer',
	HEADER: 'header',
	POPUP: 'popup',
	CALL_TO_ACTION: 'call-to-action',
	CATEGORY: 'category',
	CATEGORIES_SCORES: 'category-scores',
	TESTIMONIAL: 'testimonial',
	QUESTION: 'question',
	OVER_ALL_SCORE: 'overall-score',
	COVER: 'cover',
	HIGHEST_LOWEST_CATEGORY: 'highest-lowest-category',
	FAQ: 'faq',
})

class PageTemplateData extends BaseData {
	/**
	 * @returns {Readonly<{TIER: string, CATEGORY: string}>}
	 */
	static get GROUP_TYPES() {
		return GROUP_TYPES
	}

	/**
	 * @returns {Readonly<{POPUP: string, HEADER: string, MAIN: string, FOOTER: string}>}
	 */
	static get SORT_GROUPS() {
		return SORT_GROUPS
	}

	/**
	 * @returns {Readonly<{SING_UP_AFTER: string, SING_UP_BEFORE: string, ALWAYS: string}>}
	 */
	static get HIDDEN_CONDITIONS() {
		return HIDDEN_CONDITIONS
	}

	/**
	 * @return {Readonly<{
	 * scorecard_url: {name: string, url: string},
	 * result_url: {name: string, url: string},
	 * report_url: {name: string, url: string},
	 * email: {name: string, url: string},
	 * continue_questions_url: {name: string, url: string}
	 * }>}
	 */
	static get SHORTCUT_LINKS() {
		return SHORTCUT_LINKS
	}

	/**
	 * @returns {Readonly<{
	 * 	POPUP: string,
	 * 	COVER: string,
	 * 	VIDEO: string,
	 * 	FORM: string,
	 * 	FOOTER: string,
	 * 	HEADER: string,
	 * 	HTML_BLOCK: string,
	 * 	TESTIMONIAL: string,
	 * 	INDIVIDUAL_CATEGORY: string,
	 * 	CALL_TO_ACTION: string,
	 * 	QUESTION: string,
	 * 	OVER_ALL_SCORE: string,
	 * 	BANNER: string,
	 * 	QUESTION_PROGRESS: string,
	 * 	CATEGORY: string,
	 * 	HIGHEST_LOWEST_CATEGORY: string,
	 * 	CONTENT: string,
	 * 	CATEGORIES_SCORES: string,
	 * 	FAQ: string
	 * }>}
	 */
	static get SECTION_TYPES() {
		return SECTION_TYPES
	}

	static create(template, ignoreThemeSettings = false) {
		const getFieldGroupsStructure = fieldGroups => {
			return _.reduce(
				fieldGroups,
				(acc, { fields, ...group }, groupKey) => {
					// INFO: necessary for v3 version
					const { name: groupName, categoryId } = this.getNameInfo(groupKey)
					acc.groups[groupName] = {
						...group,
						fields: categoryId ? _.map(fields, (field, key) => this.getNameInfo(key).name) : _.keys(fields),
					}

					if (categoryId) {
						acc.groups[groupName].title = 'Category'
						acc.groups[groupName].type = this.GROUP_TYPES.CATEGORY
						acc.groups[groupName].legacy = true
					}

					acc.fields = _.reduce(
						fields,
						(fieldAcc, field, fieldKey) => {
							if (categoryId) {
								fieldKey = this.getNameInfo(fieldKey).name
							}
							fieldAcc[fieldKey] = {
								...field,
								name: fieldKey,
								group: groupName,
								withOverride: !!group.override,
							}
							return fieldAcc
						},
						acc.fields,
					)

					return acc
				},
				{ groups: {}, fields: {} },
			)
		}

		const getSectionsTemplateStructure = sections => {
			return _.reduce(
				sections,
				(acc, sectionTemplate, sectionKey) => {
					acc[sectionKey] = {
						...sectionTemplate,
						fieldgroups: getFieldGroupsStructure(sectionTemplate.fieldgroups),
					}
					return acc
				},
				{},
			)
		}

		const data = {
			...template,
			theme_settings: ignoreThemeSettings
				? template.theme_settings
				: getFieldGroupsStructure(template.theme_settings),
		}

		if (template.sections) {
			data.sections = getSectionsTemplateStructure(template.sections)
		}

		if (template.section_groups) {
			data.section_groups = _.reduce(
				template.section_groups,
				(acc, group, groupKey) => {
					acc[groupKey] = {
						...group,
						sections: getSectionsTemplateStructure(group.sections),
					}

					return acc
				},
				{},
			)
		}

		return new this(data)
	}

	static getNameInfo = name => {
		const matches = name.match(/(.+)_([0-9]+)$/)

		return matches
			? {
					name: matches[1],
					categoryId: +matches[2],
			  }
			: { name }
	}

	static isHiddenOnCondition(condition, scorecard) {
		const scorecardData = new ScorecardData(scorecard)

		switch (condition) {
			case this.HIDDEN_CONDITIONS.SING_UP_BEFORE:
				return scorecardData.isSignUpBeforeQuestion
			case this.HIDDEN_CONDITIONS.SING_UP_AFTER:
				return scorecardData.isSignUpAfterQuestion
			case this.HIDDEN_CONDITIONS.ALWAYS:
				return true
			default:
				return false
		}
	}

	static isGroupTypeCategory = type => {
		return type === this.GROUP_TYPES.CATEGORY
	}

	static isGroupTypeTier = type => {
		return type === this.GROUP_TYPES.TIER
	}

	static isSortGroupHeader = sortGroup => {
		return sortGroup === this.SORT_GROUPS.HEADER
	}

	static isSortGroupMain = sortGroup => {
		return sortGroup === this.SORT_GROUPS.MAIN
	}

	static isSortGroupFooter = sortGroup => {
		return sortGroup === this.SORT_GROUPS.FOOTER
	}

	static isSortGroupPopup = sortGroup => {
		return sortGroup === this.SORT_GROUPS.POPUP
	}

	static getDefinedLink = key => {
		return this.SHORTCUT_LINKS[key]
	}
}

export default PageTemplateData
