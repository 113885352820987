/* eslint-disable class-methods-use-this */

import BaseModel, { Action, Thunk } from '@v4/utils/model/BaseModel'
import PageTemplateData from '@v4/utils/data/PageTemplateData'

const { data, template } = window.initialData.theme || {}

class ThemeModel extends BaseModel {
	get initialData() {
		return {
			loading: false,
			data: data
				? {
						...data,
						theme_settings: _.keyBy(data.theme_settings, 'meta_key'),
						sections: _.reduce(
							data.sections,
							(acc, section) => {
								acc[section.key] = {
									...section,
									meta: _.keyBy(section.meta, 'meta_key'),
								}
								return acc
							},
							{},
						),
				  }
				: null,
			template: template ? PageTemplateData.create(template, true).data : null,
			activeGroup: null,
			scorecardData: {},
			updating: false,
			gallery: null,
		}
	}

	@Action
	toggleActiveGroup() {}

	@Action
	updateScorecardData() {}

	@Action
	setFieldValue() {}

	@Thunk
	update() {}

	@Action
	setGallery() {}

	@Action
	updateGallery() {}

	@Action
	setMeta() {}

	@Action
	setBulkMeta() {}
}

export default ThemeModel
