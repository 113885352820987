import { APP_MODE } from '@v4/constants/general'

export const checkAppMode = (mode, accept) => accept && mode && accept === mode
export const checkIsBuilderMode = mode => checkAppMode(mode, APP_MODE.builder)
export const checkIsScorecardMode = mode => checkAppMode(mode, APP_MODE.scorecard)
export const checkIsScreenshotMode = mode => checkAppMode(mode, APP_MODE.screenshot)

export const openWindow = ({ origin, pathname, search }, target) => {
	const url = `${origin || ''}${pathname}${search ? `?${search}` : ''}`

	if (target) {
		window.open(url, target)
	} else if (origin) {
		window.location.href = url
	} else {
		window.location.pathname = pathname
	}
}

export const getUserTimeZone = () => {
	try {
		const userTZ = Intl.DateTimeFormat().resolvedOptions().timeZone

		return userTZ || null
	} catch (error) {
		return null
	}
}

export const getUserLocale = () => {
	try {
		const userLocale = _.replace(new Intl.NumberFormat().resolvedOptions().locale, /-/g, '_')

		return userLocale || null
	} catch (error) {
		return null
	}
}
