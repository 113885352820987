import axios from 'axios'
import { addInterceptor } from '../utils/interceptors'

// Axios default config
const scoreappApi = axios.create({
	baseURL: `${window.location.origin}/api${window.initialData.base_url || ''}`,
	headers: { 'X-Requested-With': 'XMLHttpRequest' },
	xsrfCookieName: null,
})

addInterceptor(scoreappApi)

export default scoreappApi
