import { action, actionOn, computed } from 'easy-peasy'
import { NO_SIGNUP } from '@v4/constants/quiz'
import { parseScorePercent } from '@v4/utils'

export default {
	data: window.initialData.result || { key: NO_SIGNUP },
	loading: false,
	blocked: false,
	test: true,
	setData: action((state, payload) => {
		state.data = {
			...state.data,
			...payload,
		}
	}),

	setBlocked: action((state, payload) => {
		state.blocked = payload
	}),

	scores: computed([state => state.data.scores, (state, storeState) => storeState.scoreTiers], (scores, scoreTiers) =>
		_.reduce(
			scores,
			(acc, { category, ...score }) => {
				const currentScoreTier = _.find(scoreTiers, tier => {
					const scorePercent = parseScorePercent(score.score_percent)

					return scorePercent >= tier.score_from && scorePercent <= tier.score_to
				})
				if (!currentScoreTier) {
					return acc
				}
				acc[category ? category.id : 'total'] = _.reduce(
					scoreTiers,
					(tierAcc, scoreTier) => {
						const percent = (scoreTier.score_to + scoreTier.score_from) / 2 / 100

						tierAcc[scoreTier.id] =
							currentScoreTier.id === scoreTier.id
								? {
										...score,
										name: scoreTier.name,
								  }
								: {
										id: score.id,
										type: score.type,
										score_potential: score.score_potential,
										score: Math.round(score.score_potential * percent).toFixed(2),
										score_percent: percent.toFixed(2),
										name: scoreTier.name,
								  }
						return tierAcc
					},
					{
						default: {
							...score,
							name: currentScoreTier.name,
						},
					},
				)
				return acc
			},
			{},
		),
	),

	onSendAnswerSuccess: actionOn(
		(actions, storeActions) => [storeActions.question.sendAnswer.successType],
		(state, { result }) => {
			if (result.data.result) {
				state.data = result.data.result
			}
		},
	),

	onSuccessSingUp: actionOn(
		(actions, storeActions) => [
			storeActions.signUp.completeSingUp.successType,
			storeActions.signUp.updateSingUp.successType,
		],
		(state, { result: { result } }) => {
			state.data = {
				...state.data,
				...result,
			}
		},
	),
}
