/* eslint-disable class-methods-use-this */
import BaseModel, { Action } from '@v4/utils/model/BaseModel'

class ColorsModel extends BaseModel {
	get initialData() {
		return {
			recent: [],
		}
	}

	@Action
	addRecent() {}
}

export default ColorsModel
