import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, MemoryRouter } from 'react-router-dom'
import { useAppRouter } from './AppRouter.hooks'

const AppRouter = props => {
	const [{ basename, children, isScorecardMode }] = useAppRouter(props)

	return isScorecardMode ? (
		<BrowserRouter basename={basename} forceRefresh>
			{children}
		</BrowserRouter>
	) : (
		<MemoryRouter>{children}</MemoryRouter>
	)
}

AppRouter.propTypes = {
	children: PropTypes.node,
}

export default AppRouter
