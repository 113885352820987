/* eslint-disable class-methods-use-this */
import BaseModel from '@v4/utils/model/BaseModel'

class ScoreTiers extends BaseModel {
	get initialData() {
		return {
			items: window.initialData.score_tiers || [],
		}
	}
}

export default ScoreTiers
