import AppStore from '@v4/store/appStore'
import { useEffect } from 'react'

export const useAppRouter = ({ children }) => {
	const isScorecardMode = AppStore.useStoreState(state => state.general.isScorecardMode)
	const basename = AppStore.useStoreState(state => state.general.basename)
	const { setAutoHeight } = AppStore.useStoreActions(actions => actions.general)

	useEffect(() => {
		if (window === window.parent) {
			return undefined
		}

		const handleMessage = event => {
			try {
				if (event.data.scoreapp && event.data.scoreapp.autoHeight) {
					setAutoHeight(true)
				}
				// eslint-disable-next-line no-empty
			} catch (e) {}
		}
		window.addEventListener('message', handleMessage)

		return () => {
			window.removeEventListener('message', handleMessage)
		}
	}, [setAutoHeight])

	return [
		{
			isScorecardMode,
			basename,
			children,
		},
	]
}
