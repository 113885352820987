import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(
	() => ({
		root: {
			minHeight: '100vh',
			display: 'flex',
			backgroundColor: 'white',
			justifyContent: 'center',
			alignItems: 'center',
			fontFamily: 'brandon-grotesque, sans-serif',
			padding: 20,
			'& h1': {
				margin: 0,
			},
			'& p': {
				color: '#546880',
				fontSize: 15,
				'& span': {
					textDecoration: 'underline',
					cursor: 'pointer',
					'&:hover': {
						textDecoration: 'none',
					},
				},
			},
		},
		icon: {
			width: 'auto',
			height: 'auto',
			fontSize: '2.45rem',
			color: '#db2928',
			marginRight: '1rem',
		},
	}),
	{ name: 'ErrorContent' },
)

export default useStyles
