import * as Yup from 'yup'
import { EMAIL_DELIVERABILITY_OPTIONS, EMAIL_DELIVERABILITY_STATUS } from '@v4/constants/validation'
import emailValidationApi from '@v4/api/emailValidationApi'
import { getWording } from '@v4/utils/wordings'
import { createErrorNotification } from '@v4/utils/bugsnag'

const YUP_PHONE_METHOD = 'phone'
const CLDR_REGION_CODE_SIZE = 2

const isValidCountryCode = countryCode =>
	typeof countryCode === 'string' && countryCode.length === CLDR_REGION_CODE_SIZE

Yup.addMethod(Yup.string, YUP_PHONE_METHOD, function yupPhone(countryCode, strict = false, errorMessage = '') {
	let errMsg
	if (errorMessage && ['string', 'function'].includes(typeof errorMessage)) {
		errMsg = errorMessage
	} else if (isValidCountryCode(countryCode)) {
		errMsg = `\${path} must be a valid phone number for region ${countryCode}`
	} else {
		errMsg = `\${path} must be a valid phone number.`
	}
	// @ts-ignore
	return this.test(YUP_PHONE_METHOD, errMsg, value => {
		const phoneUtil = window.libphonenumber?.PhoneNumberUtil.getInstance()
		if (!value || !phoneUtil) return true
		if (!isValidCountryCode(countryCode)) {
			// if not valid countryCode, then set default country to Great Britain
			// eslint-disable-next-line no-param-reassign
			countryCode = 'GB'
			// eslint-disable-next-line no-param-reassign
			strict = false
		}

		try {
			const phoneNumber = phoneUtil.parseAndKeepRawInput(value, countryCode)

			if (!phoneUtil.isPossibleNumber(phoneNumber)) {
				return false
			}

			const regionCodeFromPhoneNumber = phoneUtil.getRegionCodeForNumber(phoneNumber)

			/* check if the countryCode provided should be used as
			 default country code or strictly followed
		   */
			return strict
				? phoneUtil.isValidNumberForRegion(phoneNumber, countryCode)
				: phoneUtil.isValidNumberForRegion(phoneNumber, regionCodeFromPhoneNumber)
		} catch {
			return false
		}
	})
})

export const validateEmailAdvance = async (email, options) => {
	const apiKey = import.meta.env.VITE_ABSTRACT_EMAIL_API_KEY
	const { enabled, deliverability, allow_free_providers: allowFreeProviders } = options || {}

	if (!enabled || !email || !apiKey) {
		return null
	}

	try {
		const { data } = await emailValidationApi.get('', {
			params: {
				api_key: apiKey,
				email,
			},
		})

		const isValidFormat = data.is_valid_format.value
		const isDisposableEmail = data.is_disposable_email.value
		const isFreeEmailBlocked = !allowFreeProviders && data.is_free_email.value

		const errorMessage = getWording('advancedValidationEmail')

		if (!isValidFormat || isDisposableEmail || isFreeEmailBlocked) return errorMessage

		const deliverabilityStatus = data.deliverability

		switch (deliverability) {
			case EMAIL_DELIVERABILITY_OPTIONS.BLOCK_UNDELIVERABLE: {
				return deliverabilityStatus === EMAIL_DELIVERABILITY_STATUS.UNDELIVERABLE ? errorMessage : null
			}
			case EMAIL_DELIVERABILITY_OPTIONS.BLOCK_UNDELIVERABLE_AND_UNKNOWN: {
				return [EMAIL_DELIVERABILITY_STATUS.UNKNOWN, EMAIL_DELIVERABILITY_STATUS.UNDELIVERABLE].includes(
					deliverabilityStatus,
				)
					? errorMessage
					: null
			}
			default:
				return null
		}
	} catch (error) {
		createErrorNotification('Abstract Email Validation Error')(error)
		return null
	}
}
