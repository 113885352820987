import { withStyles } from '@material-ui/core/styles'

export default withStyles(
	{
		root: {
			minHeight: '100vh',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			textAlign: 'center',
			alignItems: 'center',
			fontFamily: 'sans-serif',
			'$ h1': {
				margin: 0,
			},
		},
	},
	{ name: 'ErrorBoundary' },
)
