import i18next from '@v4/utils/i18n'
import { useEffect } from 'react'
import ScorecardSelector from '@v4/utils/selectors/ScorecardSelector'

export const useI18nextProvider = ({ children }) => {
	const locale = ScorecardSelector.useLocale()

	useEffect(() => {
		if (locale && i18next.language !== locale) {
			i18next.changeLanguage(locale)
		}
	}, [locale])

	return [
		{
			children,
			i18next,
		},
	]
}
