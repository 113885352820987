import BaseData from './BaseData'

const VERSIONS = Object.freeze({
	LEGACY: 'legacy',
	V2: 'v2',
	V3: 'v3',
	V4: 'v4',
})

const TYPES = Object.freeze({
	LANDING: 'landing',
	RESULT: 'result',
	QUESTION: 'question',
	RESULT_PDF: 'result-pdf',
})

const STATUSES = Object.freeze({
	PUBLISHED: 'published',
	DRAFT: 'draft',
})

const SETTINGS_VALUE_TYPES = Object.freeze({
	STATUS: 'status',
	HOME_PAGE: 'is_default_page',
})

class PageData extends BaseData {
	/**
	 * @returns {Readonly<{LEGACY: string, V2: string, V3: string, V4: string}>}
	 */
	static get VERSIONS() {
		return VERSIONS
	}

	/**
	 * @returns {Readonly<{RESULT_PDF: string, LANDING: string, RESULT: string, QUESTION: string}>}
	 */
	static get TYPES() {
		return TYPES
	}

	/**
	 * @returns {Readonly<{PUBLISHED: string, DRAFT: string}>}
	 */
	static get STATUSES() {
		return STATUSES
	}

	/**
	 * @returns {Readonly<{STATUS: string, HOME_PAGE: string}>}
	 */
	static get SETTINGS_VALUE_TYPES() {
		return SETTINGS_VALUE_TYPES
	}

	get version() {
		return this.data.version
	}

	get type() {
		return this.data.type
	}

	get status() {
		return this.data.status
	}

	get previewUrl() {
		if (!this.isTypeLanding) {
			return ''
		}
		return [this.data.preview_url, this.isStatusDraft ? '?preview' : ''].join('')
	}

	get isDefault() {
		return this.data.is_default_page
	}

	static isVersionLegacy = version => version === this.VERSIONS.LEGACY

	get isVersionLegacy() {
		return this.constructor.isVersionLegacy(this.version)
	}

	static isVersionV2 = version => version === this.VERSIONS.V2

	get isVersionV2() {
		return this.constructor.isVersionV2(this.version)
	}

	static isVersionV3 = version => version === this.VERSIONS.V3

	get isVersionV3() {
		return this.constructor.isVersionV3(this.version)
	}

	static isVersionV4 = version => version === this.VERSIONS.V4

	get isVersionV4() {
		return this.constructor.isVersionV4(this.version)
	}

	static isTypeLanding = type => type === this.TYPES.LANDING

	get isTypeLanding() {
		return this.constructor.isTypeLanding(this.type)
	}

	static isTypeQuestions = type => type === this.TYPES.QUESTION

	get isTypeQuestions() {
		return this.constructor.isTypeQuestions(this.type)
	}

	static isTypeResult = type => type === this.TYPES.RESULT

	get isTypeResult() {
		return this.constructor.isTypeResult(this.type)
	}

	static isTypeResultPdf = type => type === this.TYPES.RESULT_PDF

	get isTypeResultPdf() {
		return this.constructor.isTypeResultPdf(this.type)
	}

	static isStatusPublished = type => type === this.STATUSES.PUBLISHED

	get isStatusPublished() {
		return this.constructor.isStatusPublished(this.status)
	}

	static isStatusDraft = type => type === this.STATUSES.DRAFT_MODE

	get isStatusDraft() {
		return this.constructor.isStatusDraft(this.status)
	}

	get id() {
		return this.data.id
	}

	get title() {
		return this.data.title
	}

	get slug() {
		return this.data.slug
	}

	get isDefaultPage() {
		return this.data.is_default_page
	}

	get customCss() {
		return this.data.custom_css
	}

	set customCss(value) {
		this.fill({ custom_css: value || null })
	}

	get customHeadScript() {
		return this.data.head_script
	}

	set customHeadScript(value) {
		this.fill({ head_script: value || null })
	}

	get customBodyScript() {
		return this.data.custom_script
	}

	set customBodyScript(value) {
		this.fill({ custom_script: value || null })
	}

	static getSectionLabel = pageType => (PageData.isTypeResultPdf(pageType) ? 'page' : 'section')

	static getPageLabel = pageType => (PageData.isTypeResultPdf(pageType) ? 'PDF' : 'page')
}

export default PageData
