const QUESTION_TYPE = {
	MULTI_BUTTON: 'multiplechoice_button',
	CHECKBOX: 'multiplechoice_checkbox',
	MULTI_RADIO: 'multiplechoice_radio_button', // doesn't fit terminology
	BUTTON: 'yesno_button',
	RADIO: 'range_radio_button',
	SELECTOR: 'range_selector',
	SLIDER: 'range_slider',
	DIVIDED_SCALE: 'range_divided_scale',
	TEXT: 'text_text',
	MULTI_IMAGE_CHECKBOX: 'multiplechoice_image_checkbox',
	MULTI_IMAGE_BUTTON: 'multiplechoice_image_button',
	PLACEHOLDER: 'placeholder_basic',
}

export const NO_SIGNUP = 'no-sign-up'

export const ALIGN = {
	LEFT: 'left',
	CENTER: 'center',
	RIGHT: 'right',
}

export const FLEX = {
	START: 'flex-start',
	CENTER: 'center',
	END: 'flex-end',
	BETWEEN: 'space-between',
	AROUND: 'space-around',
	EVENLY: 'space-evenly',
}

export const ALIGN_REL = {
	[FLEX.START]: ALIGN.LEFT,
	[FLEX.CENTER]: ALIGN.CENTER,
}

export const STATUS_TYPE = {
	FINISHED: 'quiz_finished',
	IN_PROGRESS: 'in_progress',
	NO_QUESTIONS: 'no_questions',
}

export const QUESTION_ORDER = {
	BRANCHING_LOGIC: 'branching_logic',
	ASC_CATEGORY: 'asc_category',
	ASC: 'asc',
	RANDOM: 'random',
}

export const OBJECT_FIT = {
	CONTAIN: 'contain',
	COVER: 'cover',
}

export default QUESTION_TYPE
