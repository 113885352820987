import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(
	() => ({
		root: {
			display: 'flex',
			alignItems: 'baseline',
			justifyContent: 'center',
			'& > span': {
				fontSize: '0.4em',
				width: 0,
				height: 0,
			},
		},
	}),
	{ name: 'Denominator' },
)

export default useStyles
