/* eslint-disable class-methods-use-this */
import BaseModel from '@v4/utils/model/BaseModel'

class ScorecardModel extends BaseModel {
	get initialData() {
		return {
			data: window.initialData.scorecard,
		}
	}
}

export default ScorecardModel
