import BugSnag from '@bugsnag/js'

export const createErrorNotification = (message, payload) => error => {
	if (error instanceof Error && error.message === 'Network Error') {
		return
	}
	if (payload) {
		BugSnag.addMetadata('payload', payload)
	}

	const response = error instanceof Error ? error.response?.data : error
	if (response) {
		BugSnag.addMetadata('response', response)
	}

	BugSnag.notify(message)
	BugSnag.addMetadata('payload', null)
	BugSnag.addMetadata('response', null)
}
