import { TrackingEvents } from '@v4/constants/tracking'

class GTM {
	static trackEvent(event, data) {
		switch (event) {
			case TrackingEvents.LEAD:
				this.track(event, data)
				this.track('LeadSignedUp', data)
				break
			case TrackingEvents.QUESTIONS_STARTED:
				this.track(event, data)
				this.track('QuizStarted', data)
				break
			case TrackingEvents.QUESTIONS_FINISHED:
				this.track(event, data)
				this.track('QuizFinished', data)
				break
			case TrackingEvents.LEAD_DETAILS_UPDATED:
				this.track(event, data)
				this.track('LeadDetailsUpdated', data)
				break
			case TrackingEvents.LEAD_FORM_MODAL_OPENED:
			case TrackingEvents.QUESTION_ANSWERED:
				this.track(event, data)
				break
			default:
				break
		}
	}

	static track(event, data) {
		window.dataLayer = window.dataLayer || []
		window.dataLayer.push({
			event,
			...data,
		})
	}
}

export default GTM
