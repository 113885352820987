import './bootstrap'
import React from 'react'
import ReactDOM from 'react-dom'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import '@v4/utils/validations'
import { APP_MODE, BUILDER_VERSION } from '@v4/constants/general'
import ROUTES from '@v4/constants/route'

import { checkIsBuilderMode } from '@v4/utils/general'
import VersionError from '@v4/components/common/VersionError'
import App from './app'

Bugsnag.start({
	apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
	plugins: [new BugsnagPluginReact()],
	releaseStage: window.initialData.bug_tracking ? import.meta.env.VITE_BUGSNAG_RELEASE_STAGE : 'null',
	enabledReleaseStages: ['production', 'staging'],
	onError: event => {
		const message = event.originalError?.message || ''
		return (
			// eslint-disable-next-line no-underscore-dangle
			!event._metadata?.['unhandledrejection handler'] &&
			!new RegExp('fbq', 'gi').test(message) &&
			!new RegExp('ResizeObserver loop limit exceeded', 'gi').test(message)
		)
	},
})

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

class FrameState {
	constructor({ active = false, root = document.getElementById('root'), version, history, initialScroll }) {
		const state = { active, version, initialScroll }

		this.root = root
		this.state = state
		this.handleMount(state)
		this.history = history
	}

	setState(state) {
		this.state = { ...this.state, ...state }
		this.handleMount(state)
	}

	setHistory(history) {
		this.history = history
	}

	addBugsnagMetaData = (event, data) => {
		Bugsnag.addMetadata(event, data)
	}

	handleMount(state) {
		const { active = false, type, version, initialScroll } = state
		if (!active) ReactDOM.unmountComponentAtNode(this.root)
		else if (version !== BUILDER_VERSION) {
			ReactDOM.render(<VersionError />, this.root)
		} else {
			ReactDOM.render(
				<ErrorBoundary>
					<App type={type} initialScroll={initialScroll} />
				</ErrorBoundary>,
				this.root,
			)
		}
	}
}
const active =
	[APP_MODE.scorecard, APP_MODE.screenshot].includes(window.initialData.mode) ||
	window.location.pathname === `${window.initialData.base_url}${ROUTES.START}`

window.FrameState = new FrameState({
	active,
	version: active ? BUILDER_VERSION : undefined,
})

if (checkIsBuilderMode(window.initialData.mode)) {
	window.addEventListener('unload', () => {
		window.FrameState.setState({ active: false })
	})
}

export default FrameState
