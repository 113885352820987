import Bugsnag from '@bugsnag/js'
import { parseValidationError } from './index'
import { getXsrfToken } from './cookie'

const storeCrossSiteData = ({ sasid }) => {
	if (sasid) window.initialData.sasid = sasid
}

const addInterceptor = axiosInstance => {
	axiosInstance.interceptors.response.use(
		response => {
			storeCrossSiteData(response.headers)

			return response
		},
		error => {
			if (error?.response instanceof Object) {
				const { status, data, headers } = error.response
				storeCrossSiteData(headers)

				if (status === 419) {
					if (error.config) {
						Bugsnag.addMetadata('headers', error.config.headers)
						Bugsnag.addMetadata('payload', error.config.data)
					}

					if (data) {
						Bugsnag.addMetadata('response', data)
					}

					Bugsnag.addMetadata('settings', {
						cookieEnabled: navigator.cookieEnabled,
					})

					Bugsnag.notify('419 Error')
					Bugsnag.addMetadata('headers', null)
					Bugsnag.addMetadata('payload', null)
					Bugsnag.addMetadata('response', null)
					Bugsnag.addMetadata('settings', null)
				}

				if (status === 422) {
					return Promise.reject(data.errors ? parseValidationError(data.errors) : data.error)
				}
			}
			return Promise.reject(error)
		},
	)

	axiosInstance.interceptors.request.use(config => {
		const xsrfToken = getXsrfToken()

		if (xsrfToken) {
			// eslint-disable-next-line no-param-reassign
			config.headers['X-XSRF-TOKEN'] = decodeURIComponent(xsrfToken)
		} else {
			// eslint-disable-next-line no-param-reassign
			config.headers['X-CSRF-TOKEN'] =
				window.initialData.tokens?.csrf ||
				document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
		}

		try {
			if (window.parent.location.host !== window.location.host) {
				throw new Error()
			}
		} catch (e) {
			// eslint-disable-next-line no-param-reassign
			config.headers.sasid = window.initialData.sasid
		}

		return config
	})
}

// eslint-disable-next-line import/prefer-default-export
export { addInterceptor }
