import React from 'react'
import { Icon } from '@material-ui/core'
import clsx from 'clsx'
import useStyles from './ErrorContent.styled'

const ErrorContent = () => {
	const classes = useStyles()
	return (
		<div className={classes.root}>
			<Icon className={clsx(classes.icon, 'far fa-exclamation-triangle')} />
			<p>
				<strong>Ooops - </strong>It looks like your browser is trying to access an older version of this app.
				<br />
				Please hard refresh your browser. If the problem persists&nbsp; please{' '}
				<span data-action="contact-support">contact support.</span>
			</p>
		</div>
	)
}

export default ErrorContent
