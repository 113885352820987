import { TrackingEvents } from '@v4/constants/tracking'

class FB {
	static trackEvent(event) {
		if (!window.fbq) return

		// Convert event name from snake_case to PascalCase
		const pascalCaseEvent = event
			.split('_')
			.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
			.join('')

		switch (event) {
			case TrackingEvents.LEAD:
				// FB has a standard lead event, so we should use it
				window.fbq('track', 'Lead')
				break
			case TrackingEvents.QUESTIONS_STARTED:
				window.fbq('trackCustom', pascalCaseEvent)
				window.fbq('trackCustom', 'ScorecardStarted')
				break
			case TrackingEvents.QUESTIONS_FINISHED:
				window.fbq('trackCustom', pascalCaseEvent)
				window.fbq('trackCustom', 'ScorecardFinished')
				break
			case TrackingEvents.LEAD_DETAILS_UPDATED:
				window.fbq('trackCustom', pascalCaseEvent)
				window.fbq('trackCustom', 'LeadDetailsUpdated')
				break
			default:
				break
		}
	}
}

export default FB
