import GTM from '@v4/utils/tracking/gtm'
import FB from '@v4/utils/tracking/fb'
import GA from '@v4/utils/tracking/ga'

class Tracking {
	static track(event, trackingData) {
		const scorecard = window.initialData?.scorecard || {}
		const data = {
			...trackingData,
			scorecard_key: scorecard.key,
			scorecard_name: scorecard.name,
		}

		// Dispatch custom event using the event name in camelCase
		const camelCaseEvent = _.camelCase(event)
		const customEvent = new CustomEvent(camelCaseEvent, { detail: data })
		window.dispatchEvent(customEvent)

		// if embedded send message to parent
		if (window.self !== window.top) {
			const settings = scorecard.settings || {}

			const pixels = {
				facebook_pixel_id: settings.facebook_pixel_id,
				gtm_id: settings.gtm_id,
			}
			window.parent.postMessage({ event, data, pixels }, '*')
			return
		}

		FB.trackEvent(event, data)
		GTM.trackEvent(event, data)
		GA.trackEvent(event, data)
	}
}

export default Tracking
