/* eslint-disable class-methods-use-this */
import BaseModel, { Action, Thunk } from '@v4/utils/model/BaseModel'

class QuizQuestionsModel extends BaseModel {
	get initialData() {
		const { result = {}, categories: initCategories } = window.initialData

		const categories = _.isEmpty(initCategories)
			? _.reduce(
					result.scores,
					(acc, { category }) => {
						if (category) {
							acc.ids.push(category.id)
							acc.entities[category.id] = category
						}
						return acc
					},
					{
						ids: [],
						entities: {},
					},
			  )
			: _.reduce(
					initCategories,
					(acc, category) => {
						if (category) {
							acc.ids.push(category.id)
							acc.entities[category.id] = category
						}
						return acc
					},
					{
						ids: [],
						entities: {},
					},
			  )
		return {
			questions: {
				ids: [],
				entities: {},
			},
			questionSettings: {},
			questionOptions: {},
			templates: [],
			categories,
			loading: true,
			error: false,
			updating: false,
			initial: {
				questions: {
					ids: [],
					entities: {},
				},
				categories: _.cloneDeep(categories),
			},
			deletingCategoryIds: [],
			deletingQuestionIds: [],
			deletingOptionIds: [],
			optionDependencies: {},
		}
	}

	@Thunk
	fetch() {}

	@Thunk
	update() {}

	@Thunk
	deleteQuestion() {}

	@Thunk
	deleteCategory() {}

	@Action
	sortQuestions() {}

	@Action
	addQuestion() {}

	@Action
	updateQuestion() {}

	@Action
	updateQuestionSettings() {}

	@Action
	setQuestionAnswerType() {}

	@Action
	addQuestionOption() {}

	@Action
	deleteQuestionOption() {}

	@Action
	updateQuestionOption() {}

	@Action
	sortQuestionOptions() {}

	@Action
	sortCategories() {}

	@Action
	addCategory() {}

	@Action
	updateCategory() {}
}

export default QuizQuestionsModel
