import React from 'react'
import { StylesProvider, createGenerateClassName } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import ErrorContent from './components/ErrorContent'

const generateClassName = createGenerateClassName({
	productionPrefix: 'score-app-',
})

const VersionError = () => {
	return (
		<StylesProvider generateClassName={generateClassName}>
			<CssBaseline />
			<ErrorContent />
		</StylesProvider>
	)
}

export default VersionError
