import { actionOn, computed, thunk } from 'easy-peasy'

import { QUESTION_ORDER, STATUS_TYPE } from '@v4/constants/quiz'
import scoreappApi from '@v4/api/scoreappApi'
import Tracking from '@v4/utils/tracking'
import { TrackingEvents } from '@v4/constants/tracking'

const prepareQuestion = question => {
	const { settings } = question
	if (
		question.answer_type === 'multiplechoice' &&
		settings &&
		settings.type === 'checkbox' &&
		settings.style === 'button'
	) {
		return {
			...question,
			settings: {
				...settings,
				type: 'button',
				allow_multiple_select: true,
			},
		}
	}

	return question
}

const filterAnsweredQuestions = (questions, excludedQuestion, withOrder) => {
	return _.filter(questions, question => {
		return question.id !== excludedQuestion.id && (!withOrder || question.order < excludedQuestion.order)
	})
}

const createQuestionListener = isAnswering => (state, target) => {
	const [startType, successType, failType] = target.resolvedTargets

	state.isLoading = target.type === startType

	if (target.type === successType) {
		const data = target.result?.data || {}

		const { id, order, question, ref } = state.data

		state.data = prepareQuestion(data.data || {})
		state.status = data.status || STATUS_TYPE.IN_PROGRESS

		state.isPrevQuestionExists = isAnswering || data.is_prev_asked_question_exists

		if (isAnswering) {
			if (!state.started) {
				state.started = true
				Tracking.track(TrackingEvents.QUESTIONS_STARTED)
			}

			Tracking.track(TrackingEvents.QUESTION_ANSWERED, {
				question,
				question_ref: ref,
			})

			if (state.status === STATUS_TYPE.FINISHED) {
				Tracking.track(TrackingEvents.QUESTIONS_FINISHED)
			}
			state.answeredQuestions.push({ id, order })
			state.answeredQuestions = _.uniqBy(state.answeredQuestions, 'id')
		}

		state.answeredQuestions = filterAnsweredQuestions(
			state.answeredQuestions,
			state.data,
			state.questionOrder !== QUESTION_ORDER.RANDOM,
		)
	}

	if (target.type === failType) {
		state.errors = target.result || {}
		const { status } = target.error.response || {}
		if (status === 423) {
			state.locked = true
		}
	}
}

const {
	initial_question: question = {},
	all_questions_count: allQuestionsCount,
	is_prev_asked_question_exists: isPrevQuestionExists,
	answered_questions: answeredQuestions = [],
	status,
} = window.initialData.quiz || {}

const questionOrder = window.initialData.scorecard?.settings?.question_order || QUESTION_ORDER.ASC

export default {
	isLoading: null,
	data: prepareQuestion(question),
	totalQuestions: allQuestionsCount,
	isPrevQuestionExists,
	status: status || STATUS_TYPE.IN_PROGRESS,
	errors: {},
	answeredQuestions: filterAnsweredQuestions(answeredQuestions, question, questionOrder !== QUESTION_ORDER.RANDOM),
	started: !_.isEmpty(answeredQuestions),
	locked: false,
	questionOrder,

	percentage: computed(
		[
			state => state.answeredQuestions,
			state => state.totalQuestions,
			state => state.status,
			(state, storeState) => storeState.general.isBuilderMode,
		],
		(questions, total, quizStatus, isBuilderMode) => {
			if (isBuilderMode) {
				return 50
			}
			if (quizStatus === STATUS_TYPE.FINISHED) {
				return 100
			}
			const progress = Math.round((_.size(questions) / total) * 100)

			return _.min([progress, 99])
		},
	),

	getPrevQuestion: thunk((actions, { key, id }) => {
		return scoreappApi.get(`result/${key}/question/${id}/prev`)
	}),
	sendAnswer: thunk((actions, { key, id, data }) => scoreappApi.post(`result/${key}/question/${id}/answer`, data)),

	onSendAnswer: actionOn(
		actions => [actions.sendAnswer.startType, actions.sendAnswer.successType, actions.sendAnswer.failType],
		createQuestionListener(true),
	),
	onGetPrevQuestion: actionOn(
		actions => [
			actions.getPrevQuestion.startType,
			actions.getPrevQuestion.successType,
			actions.getPrevQuestion.failType,
		],
		createQuestionListener(false),
	),
}
