/* eslint-disable react-hooks/rules-of-hooks,react-hooks/exhaustive-deps */
import { useStoreActions, useStoreState } from 'easy-peasy'
import { useMemo } from 'react'

class BaseSelector {
	#state

	static fromStoreState(store) {
		return new this(store)
	}

	constructor(state) {
		this.#state = state
	}

	get state() {
		return this.#state
	}

	selectActions() {
		return this.state
	}

	static useActions() {
		return useStoreActions(storeActions => this.fromStoreState(storeActions).selectActions())
	}

	static addSelector(propertyName, selector) {
		if (propertyName in this) {
			return
		}

		Object.defineProperty(this, propertyName, {
			value: selector,
		})

		Object.defineProperty(this, _.replace(propertyName, 'select', 'use'), {
			value(...args) {
				return useStoreState(state => this[propertyName](state, ...args))
			},
		})
	}
}

export const withStaticSelector = ({ constructor }, propertyName) => {
	Object.defineProperty(constructor, propertyName, {
		value(storeState, ...args) {
			return constructor.fromStoreState(storeState)[propertyName](...args)
		},
		writable: true,
	})
}

export const hookFromSelector = hookName => ({ constructor }, propertyName) => {
	Object.defineProperty(constructor, hookName, {
		value(...args) {
			return useStoreState(state => constructor[propertyName](state, ...args))
		},
		writable: true,
	})
}

export const hookFromSelectorCreator = hookName => (constructor, propertyName) => {
	Object.defineProperty(constructor, hookName, {
		value(...args) {
			const selector = useMemo(constructor[propertyName], [])
			return useStoreState(state => selector(state, ...args))
		},
		writable: true,
	})
}

export default BaseSelector
