import { createSelector } from 'reselect'
import UserSelector from './UserSelector'
import ScorecardData from '../data/ScorecardData'
import BaseSelector, { hookFromSelector, withStaticSelector } from './BaseSelector'

class ScorecardSelector extends BaseSelector {
	static fromStoreState(store) {
		return new this(store.activeScorecard)
	}

	/**
	 * @class ScorecardSelector
	 * @property {function(storeState: Object): Object} selectScorecard
	 * @property {function(): Object} useScorecard
	 */
	@hookFromSelector('useScorecard')
	@withStaticSelector
	selectScorecard() {
		return this.state.data
	}

	/**
	 * @class ScorecardSelector
	 * @property {function(storeState: Object): boolean} selectLoading
	 * @property {function(): boolean} useLoading
	 */
	@hookFromSelector('useLoading')
	@withStaticSelector
	selectLoading() {
		return this.state.isLoading
	}

	/**
	 * @class ScorecardSelector
	 * @property {function(storeState: Object): number} selectId
	 * @property {function(): number} useId
	 */
	@hookFromSelector('useId')
	@withStaticSelector
	selectId() {
		return this.selectScorecard().id
	}

	/**
	 * @class ScorecardSelector
	 * @property {function(storeState: Object): number} selectStatus
	 * @property {function(): number} useStatus
	 */
	@hookFromSelector('useStatus')
	@withStaticSelector
	selectStatus() {
		return this.selectScorecard().status
	}

	/**
	 * @class ScorecardSelector
	 * @property {function(storeState: Object): boolean} selectIsStatusTemplate
	 * @property {function(): boolean} useIsStatusTemplate
	 */
	@hookFromSelector('useIsStatusTemplate')
	@withStaticSelector
	selectIsStatusTemplate() {
		return ScorecardData.isStatusTemplate(this.selectStatus())
	}

	/**
	 * @class ScorecardSelector
	 * @property {function(storeState: Object): number|null} selectTemplateId
	 * @property {function(): number|null} useTemplateId
	 */
	@hookFromSelector('useTemplateId')
	@withStaticSelector
	selectTemplateId() {
		return this.selectScorecard().template_id
	}

	/**
	 * @class ScorecardSelector
	 * @property {function(storeState: Object): boolean} selectIsNested
	 * @property {function(): boolean} useIsNested
	 */
	@hookFromSelector('useIsNested')
	@withStaticSelector
	selectIsNested() {
		return !!this.selectTemplateId()
	}

	/**
	 * @class ScorecardSelector
	 * @property {function(storeState: Object): number|null} selectTemplateAccessSettings
	 * @property {function(): number|null} useTemplateAccessSettings
	 */
	@hookFromSelector('useTemplateAccessSettings')
	@withStaticSelector
	selectTemplateAccessSettings() {
		return this.selectScorecard().template_access_settings
	}

	/**
	 * @class ScorecardSelector
	 * @property {function(storeState: Object): boolean} selectIsCookieLocked
	 * @property {function(): boolean} useIsCookieLocked
	 */
	@hookFromSelector('useIsCookieLocked')
	@withStaticSelector
	selectIsCookieLocked() {
		return ScorecardData.isCookieLocked(this.selectTemplateAccessSettings())
	}

	/**
	 * @class ScorecardSelector
	 * @property {function(storeState: Object): Object} selectSettings
	 * @property {function(): Object} useSettings
	 */
	@hookFromSelector('useSettings')
	@withStaticSelector
	selectSettings() {
		return new ScorecardData(this.selectScorecard()).settings
	}

	/**
	 * @class ScorecardSelector
	 * @property {function(storeState: Object): Object} selectSettings
	 * @property {function(): Object} useSettings
	 */

	@hookFromSelector('useDirection')
	@withStaticSelector
	selectDirection() {
		return this.selectSettings()?.locale_text_direction
	}

	/**
	 * @class ScorecardSelector
	 * @property {function(storeState: Object): ?number} selectSignUpLocation
	 * @property {function(): ?number} useSignUpLocation
	 */
	@hookFromSelector('useSignUpLocation')
	@withStaticSelector
	selectSignUpLocation() {
		return new ScorecardData(this.selectScorecard()).singUpLocation
	}

	/**
	 * @class ScorecardSelector
	 * @property {function(storeState: Object): ?string} selectLocale
	 * @property {function(): ?string} useLocale
	 */
	@hookFromSelector('useLocale')
	@withStaticSelector
	selectLocale() {
		return this.selectSettings()?.locale || 'en'
	}
}

ScorecardSelector.addSelector(
	'selectIsLockedFieldEditable',
	createSelector(
		[UserSelector.selectHasAdminPermissions, ScorecardSelector.selectIsStatusTemplate],
		(hasPermissions, isTemplate) => {
			return hasPermissions || isTemplate
		},
	),
)

ScorecardSelector.addSelector(
	'selectIsSectionsLocked',
	createSelector(
		[
			UserSelector.selectHasAdminPermissions,
			ScorecardSelector.selectIsNested,
			ScorecardSelector.selectTemplateAccessSettings,
		],
		(hasPermissions, isNested, templateAccessSettings) => {
			return !hasPermissions && isNested && ScorecardData.isSectionsLocked(templateAccessSettings)
		},
	),
)

ScorecardSelector.addSelector(
	'selectIsQuestionsLocked',
	createSelector(
		[
			UserSelector.selectHasAdminPermissions,
			ScorecardSelector.selectIsNested,
			ScorecardSelector.selectTemplateAccessSettings,
		],
		(hasPermissions, isNested, templateAccessSettings) => {
			return !hasPermissions && isNested && ScorecardData.isQuestionsLocked(templateAccessSettings)
		},
	),
)

ScorecardSelector.addSelector(
	'selectIsTiersLocked',
	createSelector(
		[
			UserSelector.selectHasAdminPermissions,
			ScorecardSelector.selectIsNested,
			ScorecardSelector.selectTemplateAccessSettings,
		],
		(hasPermissions, isNested, templateAccessSettings) => {
			return !hasPermissions && isNested && ScorecardData.isTiersLocked(templateAccessSettings)
		},
	),
)

ScorecardSelector.addSelector(
	'selectIsPagesLocked',
	createSelector(
		[
			UserSelector.selectHasAdminPermissions,
			ScorecardSelector.selectIsNested,
			ScorecardSelector.selectTemplateAccessSettings,
		],
		(hasPermissions, isNested, templateAccessSettings) => {
			return !hasPermissions && isNested && ScorecardData.isPagesLocked(templateAccessSettings)
		},
	),
)

ScorecardSelector.addSelector(
	'selectIsCookieLocked',
	createSelector(
		[
			UserSelector.selectHasAdminPermissions,
			ScorecardSelector.selectIsNested,
			ScorecardSelector.selectTemplateAccessSettings,
		],
		(hasPermissions, isNested, templateAccessSettings) => {
			return !hasPermissions && isNested && ScorecardData.isCookieLocked(templateAccessSettings)
		},
	),
)

export default ScorecardSelector
