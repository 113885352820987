import React, { useEffect } from 'react'

const Fallback = () => {
	useEffect(() => {
		return () => {
			const event = new Event('lazy-loaded')

			window.dispatchEvent(event)
		}
	}, [])

	return <span />
}

export default Fallback
