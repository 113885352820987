import { actionOn, thunk } from 'easy-peasy'
import QUESTION_TYPE, { QUESTION_REL_KEY } from '@v4/constants/questions'
import { QUERY_PARAMS_TO_DELETE } from '@v4/constants/general'
import ROUTES from '@v4/constants/route'
import scoreappApi from '@v4/api/scoreappApi'
import { openWindow } from '@v4/utils/general'
import Tracking from '@v4/utils/tracking'
import { TrackingEvents } from '@v4/constants/tracking'

const data = { [QUESTION_TYPE.CHECKBOX]: [], [QUESTION_TYPE.HIDDEN]: [], [QUESTION_TYPE.OTHER]: [] }

export default {
	data: window.initialData.questions
		? window.initialData.questions.reduce((acc, item) => {
				const relKEy = QUESTION_REL_KEY[item.answer_type]
				if (acc[relKEy]) acc[relKEy].push(item)
				return acc
		  }, data)
		: data,
	answers: window.initialData.answers || [],
	resultRedirectUrl: window.initialData.result_redirect_url,

	singUp: thunk(async (actions, { values, analyticData }, { getStoreState }) => {
		const { search, pathname: locationPathname } = window.location
		const queryString = new URLSearchParams(search)
		const fbadId = queryString.get('fbad_id')

		await scoreappApi.post('start', fbadId ? { ...values, fbad_id: fbadId } : values)

		Tracking.track(TrackingEvents.LEAD, analyticData)

		const {
			general: { basename, targetWindow },
			tokens: { sasid },
		} = getStoreState()

		const pathname = `${basename}${ROUTES.QUESTIONS}`

		const isAutoStart = locationPathname === `${basename}${ROUTES.START}`

		queryString.delete('target')

		if (sasid && !queryString.has('SASID')) {
			queryString.set('SASID', sasid)
		}

		if (targetWindow === '_top') {
			_.forEach(QUERY_PARAMS_TO_DELETE, param => queryString.delete(param))
		}

		const autoStartSearch = queryString.has('SASID') ? `SASID=${queryString.get('SASID')}` : null

		openWindow(
			{
				origin: window.location.origin,
				pathname,
				search: isAutoStart ? autoStartSearch : queryString.toString(),
			},
			targetWindow,
		)
	}),
	completeSingUp: thunk(async (actions, { pageId, values }, { getStoreState }) => {
		const resultKey = getStoreState().result.data.key
		const response = await scoreappApi.post(`pages/${pageId}/complete-sign-up/${resultKey}`, values)

		return response.data
	}),
	prefill: thunk(async (actions, payload, { getStoreState }) => {
		const resultKey = getStoreState().result.data.key
		const response = await scoreappApi.put(`results/${resultKey}/prefill`, payload)

		return response.data
	}),
	updateSingUp: thunk(async (actions, { pageId, values }, { getStoreState }) => {
		const resultKey = getStoreState().result.data.key
		const response = await scoreappApi.put(`pages/${pageId}/update-sign-up/${resultKey}`, values)

		return response.data
	}),

	onSuccessSingUp: actionOn(
		actions => [actions.completeSingUp.successType, actions.updateSingUp.successType],
		(state, { resolvedTargets, type, result: { answers }, payload: { analyticData } }) => {
			const [completeSuccessType, updateSuccessType] = resolvedTargets

			state.answers = answers

			if (type === completeSuccessType) {
				Tracking.track(TrackingEvents.LEAD, analyticData)
				if (state.resultRedirectUrl) {
					window.location.href = state.resultRedirectUrl
				}
			}

			if (type === updateSuccessType) {
				Tracking.track(TrackingEvents.LEAD_DETAILS_UPDATED, analyticData)
			}
		},
	),
}
