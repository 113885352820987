import BaseData from './BaseData'

const VISIBILITY = Object.freeze({
	HIDE: 0,
	SHOW: 1,
	HIDE_AUDIENCE_BASED: 2,
	SHOW_AUDIENCE_BASED: 3,
})

class PageSectionData extends BaseData {
	/**
	 * @returns {Readonly<{HIDE: number, SHOW: number, HIDE_AUDIENCE_BASED: number, SHOW_AUDIENCE_BASED: number}>}
	 */
	static get VISIBILITY() {
		return VISIBILITY
	}

	static create = ({ pageId, key, meta }) => {
		return new this({
			...this.initialData,
			landing_page_id: pageId,
			key,
			meta,
		})
	}

	static get initialData() {
		return {
			temporaryId: this.generateTemporaryId(),
			category_id: null,
			visibility: 1,
			key: null,
			landing_page_id: null,
			audiences_ids: [],
		}
	}

	get id() {
		return this.data?.id
	}

	get temporaryId() {
		return this.data?.temporaryId
	}

	get currentId() {
		return this.temporaryId || this.id
	}

	get key() {
		return this.data?.key
	}

	get parsedKey() {
		const [group, key] = this.key?.split('/') || []

		return {
			group,
			key,
		}
	}

	get visibility() {
		return this.data?.visibility
	}

	set visibility(value) {
		this.fill({
			visibility: value,
			audiences_ids: [],
		})
	}

	get audiencesIds() {
		return this.data?.audiences_ids
	}

	get categoryId() {
		return this.data?.category_id
	}

	set categoryId(value) {
		this.fill({
			category_id: value,
		})
	}

	static isHidden = visibility => this.VISIBILITY.HIDE === visibility

	get isHidden() {
		return this.constructor.isHidden(this.visibility)
	}

	static isShown = visibility => this.VISIBILITY.SHOW === visibility

	get isShown() {
		return this.constructor.isShown(this.visibility)
	}

	static isAudienceBasedShown = visibility => this.VISIBILITY.SHOW_AUDIENCE_BASED === visibility

	get isSectionAudienceBasedShown() {
		return this.constructor.isAudienceBasedShown(this.visibility)
	}

	static isAudienceBasedHidden = visibility => this.VISIBILITY.HIDE_AUDIENCE_BASED === visibility

	get isSectionAudienceBasedHidden() {
		return this.constructor.isAudienceBasedHidden(this.visibility)
	}

	static get visibilities() {
		return Object.values(this.VISIBILITY)
	}

	static get baseVisibilities() {
		return [this.VISIBILITY.HIDE, this.VISIBILITY.SHOW]
	}

	static get audienceVisibilities() {
		return [this.VISIBILITY.HIDE_AUDIENCE_BASED, this.VISIBILITY.SHOW_AUDIENCE_BASED]
	}

	static isBaseVisibility = visibility => this.baseVisibilities.includes(visibility)

	static isAudienceVisibility = visibility => this.audienceVisibilities.includes(visibility)
}

export default PageSectionData
