import { thunk, action } from 'easy-peasy'

export default {
	mode: null,
	sortingSectionId: null,
	pageId: null,
	data: {},

	setMode: action(() => {}),

	setSortingSectionId: action(() => {}),

	fetch: thunk(() => {}),
}
