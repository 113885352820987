import { TrackingEvents } from '@v4/constants/tracking'

class GA {
	static trackEvent(event, data) {
		if (!window.gtag) return

		const gaData = {
			event_category: data.scorecard_key,
		}

		if (event === TrackingEvents.QUESTION_ANSWERED) {
			gaData.event_label = data.question
		}

		switch (event) {
			case TrackingEvents.LEAD_FORM_MODAL_OPENED:
			case TrackingEvents.LEAD:
			case TrackingEvents.QUESTIONS_STARTED:
			case TrackingEvents.QUESTIONS_FINISHED:
			case TrackingEvents.LEAD_DETAILS_UPDATED:
			case TrackingEvents.QUESTION_ANSWERED:
				window.gtag('event', event, gaData)
				break
			default:
				break
		}
	}
}

export default GA
