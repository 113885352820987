export const SCORE_TYPE = {
	TOTAL: 'total',
	CATEGORY: 'category',
}

export const SCORE_FORMAT = {
	PERCENTAGE: 'percentage',
	DENOMINATOR_10: 'denominator10',
	SCORE: 'score',
	OUT_OF_10: 'out_of_10',
	OUT_OF_10_ROUNDED: 'out_of_10_rounded',
}

export const CATEGORIES_ORDER = {
	desc: -1,
	asc: 1,
	order: 0,
}
