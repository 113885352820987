import BaseData from './BaseData'
import PageData from './PageData'

const FIELD_TYPE = Object.freeze({
	ASSET: 'asset',
	IMAGE: 'image',
	VIDEO: 'video',
	BG_IMAGE: 'background_image',
	WYSIWIG: 'wysiwyg',
	WYSIWIG_BASIC: 'wysiwyg-basic',
	TEXT: 'text',
	TEXT_COLOR: 'text_colour',
	BORDER_COLOR: 'border_colour',
	TOGGLE: 'toggle',
	COLOUR: 'colour',
	PERCENT: 'percent',
	DROPDOWN: 'dropdown',
	HREF: 'href',
	FONT: 'font',
	SLIDER: 'slider',
	THEME_COLOUR: 'theme_colour',
	HTML: 'html',
	CATEGORY_LIMIT: 'category-limit',
	VISIBILITY: 'visibility',
})

const RESOLUTION_SIZES = Object.freeze([2, 1.5, 1.25])

const EXTRA_TYPE = Object.freeze({
	DYNAMIC_STYLE: '::',
	DYNAMIC_CONTENT: ':>',
})

const ALIGNMENT = Object.freeze({
	LEFT: 'left',
	RIGHT: 'right',
	CENTER: 'center',
})

const JUSTIFY_CONTENT = Object.freeze({
	FLEX_START: 'flex-start',
	CENTER: 'center',
	FLEX_END: 'flex-end',
})

const SORT_DIRECTION = Object.freeze({
	ASC: 'asc',
	DESC: 'desc',
})

const CONTENT_DIRECTION = Object.freeze({
	[ALIGNMENT.LEFT]: SORT_DIRECTION.ASC,
	[ALIGNMENT.RIGHT]: SORT_DIRECTION.DESC,
})

export const TEXT_ALIGNMENT = Object.freeze({
	[ALIGNMENT.LEFT]: ALIGNMENT.RIGHT,
	[ALIGNMENT.RIGHT]: ALIGNMENT.LEFT,
	[ALIGNMENT.CENTER]: ALIGNMENT.LEFT,
})

const CONTENT_ALIGNMENT = Object.freeze({
	[ALIGNMENT.LEFT]: JUSTIFY_CONTENT.FLEX_START,
	[ALIGNMENT.CENTER]: JUSTIFY_CONTENT.CENTER,
	[ALIGNMENT.RIGHT]: JUSTIFY_CONTENT.FLEX_END,
})

export const CONTENT_REVERSE_DIRECTIONS = Object.freeze({
	[ALIGNMENT.LEFT]: SORT_DIRECTION.DESC,
	[ALIGNMENT.RIGHT]: SORT_DIRECTION.ASC,
})

const IMAGE_POSITION = Object.freeze({
	BACKGROUND: 'background',
	CENTER: 'center',
})

export const BUTTON_SIZE = Object.freeze({
	WIDE: 'wide',
	NARROW: 'narrow',
})

class PageMetaData extends BaseData {
	/**
	 * @return {Readonly<{
	 * ASSET: string,
	 * VIDEO: string,
	 * BORDER_COLOR: string,
	 * TOGGLE: string,
	 * TEXT: string,
	 * HREF: string,
	 * HTML: string,
	 * BG_IMAGE: string,
	 * WYSIWIG: string,
	 * FONT: string,
	 * CATEGORY_LIMIT: string,
	 * SLIDER: string,
	 * THEME_COLOUR: string,
	 * IMAGE: string,
	 * DROPDOWN: string,
	 * PERCENT: string,
	 * WYSIWIG_BASIC: string,
	 * COLOUR: string,
	 * TEXT_COLOR: string,
	 * VISIBILITY: string,
	 * }>}
	 */
	static get FIELD_TYPE() {
		return FIELD_TYPE
	}

	/**
	 * @returns {Readonly<{DYNAMIC_STYLE: string, DYNAMIC_CONTENT: string}>}
	 */
	static get EXTRA_TYPE() {
		return EXTRA_TYPE
	}

	/**
	 * @returns {number[]}
	 */
	static get RESOLUTION_SIZES() {
		return RESOLUTION_SIZES
	}

	/**
	 * @returns {Readonly<{CENTER: string, LEFT: string, RIGHT: string}>}
	 */
	static get ALIGNMENT() {
		return ALIGNMENT
	}

	static get CONTENT_REVERSE_DIRECTIONS() {
		return CONTENT_REVERSE_DIRECTIONS
	}

	/**
	 * @returns {Readonly<{ASC: string, DESC: string}>}
	 */
	static get SORT_DIRECTION() {
		return SORT_DIRECTION
	}

	static get CONTENT_DIRECTION() {
		return CONTENT_DIRECTION
	}

	/**
	 * @returns {Readonly<{
	 * 	left: string,
	 * 	center: string,
	 * 	right: string
	 * }>}
	 */
	static get TEXT_ALIGNMENT() {
		return TEXT_ALIGNMENT
	}

	/**
	 * @returns {Readonly<{CENTER: string, FLEX_END: string, FLEX_START: string}>}
	 */
	static get JUSTIFY_CONTENT() {
		return JUSTIFY_CONTENT
	}

	/**
	 * @returns {Readonly<{'[ALIGNMENT.LEFT]': string, '[ALIGNMENT.CENTER]': string, '[ALIGNMENT.RIGHT]': string}>}
	 */
	static get CONTENT_ALIGNMENT() {
		return CONTENT_ALIGNMENT
	}

	/**
	 * @returns {Readonly<{BACKGROUND: string, CENTER: string}>}
	 */

	static get IMAGE_POSITION() {
		return IMAGE_POSITION
	}

	/**
	 * @returns {Readonly<{WIDE: string, NARROW: string}>}
	 */

	static get BUTTON_SIZE() {
		return BUTTON_SIZE
	}

	overriddenMetaData = null

	#isBuilderMode

	#name

	#fallbackTierId

	#translate

	#themeSectionKey

	#preview

	#themeGallery

	constructor(
		data,
		config,
		scorecard,
		{
			themeData = null,
			pageType,
			preview,
			isBuilderMode,
			name,
			tierId,
			translate,
			themeSectionKey,
			themeGallery,
		} = {},
	) {
		super(data)

		this.config = _.cloneDeep(config || {})
		this.scorecard = scorecard || {}
		this.pageType = pageType
		this.#preview = preview
		this.#themeGallery = themeGallery
		this.#isBuilderMode = isBuilderMode
		this.#name = name
		this.#fallbackTierId = tierId
		this.#translate = translate
		this.themeData = themeData
		this.#themeSectionKey = themeSectionKey

		if (this.isAssetable) {
			this.config.theme = _.merge({}, this.config.theme, {
				group: 'images',
			})
		} else if (this.isColor) {
			this.config.theme = _.merge({}, this.config.theme, {
				group: 'colors',
			})
		}
	}

	static get initialData() {
		return {
			temporaryId: this.generateTemporaryId(),
			landing_page_id: null,
			landing_page_section_id: null,
			score_tier_id: null,
			category_id: null,
			meta_key: null,
			meta_key_index: null,
			meta_value: undefined,
			repeatable_group: null,
			locked: false,
			theme: null,
		}
	}

	clear() {
		if (this.isAssetable) {
			return this.fill({
				meta_value: this.isOverridden || this.defaultAsset || this.defaultValue ? '' : undefined,
				asset: null,
				theme: null,
			})
		}

		if (this.isOverridden) {
			return this.fill({
				meta_value: this.overriddenMetaData.metaValue ?? '',
				theme: null,
			})
		}

		return this.fill({
			meta_value: undefined,
			theme: null,
		})
	}

	override(values) {
		if (!values) {
			return this.fill({ meta_value: undefined })
		}
		const { asset, value } = values

		if (this.isAssetable) {
			return this.fill({
				asset,
				meta_value: asset ? asset.path : value,
			})
		}

		return this.fill({
			meta_value: this.constructor.parseValue(value) ?? '',
		})
	}

	saveTo(parent, path) {
		this.save()
		_.set(parent, path, this.isEmpty && this.isTemporary ? null : this.data)

		return this
	}

	clone() {
		const instance = new this.constructor(this.data, this.config, this.scorecard, {
			pageType: this.pageType,
			preview: this.#preview,
			themeGallery: this.#themeGallery,
			name: this.#name,
			isBuilderMode: this.#isBuilderMode,
			translate: this.#translate,
			themeData: this.themeData,
			tierId: this.#fallbackTierId,
			themeSectionKey: this.#themeSectionKey,
		})

		instance.overriddenMetaData = this.overriddenMetaData

		return instance
	}

	static imageThumbUrlFrom = (url, width) => {
		if (import.meta.env.VITE_CLOUDFLARE_IMAGE_RESIZE === 'false' || !url || !width) return url
		const regex = /\.(svg|unknown)$/g

		// eslint-disable-next-line max-len
		const path = `https://cdn.scoreapp.com/cdn-cgi/image/onerror=redirect,format=auto,width=${width},quality=75,fit=scale-down/${url}`

		return !regex.test(url) ? path : url
	}

	static imageResolutionSizeFrom = (breakpoint, size) => {
		const setSizeDpi = 96 * size
		const media = breakpoint.replace('@media ', '')
		return `${breakpoint} and (min-resolution: ${setSizeDpi}dpi),
						 ${media} and (-webkit-min-device-pixel-ratio: ${size})`
	}

	static findMeta(metaMap, params) {
		const { name, group, index, tierId = null, categoryId = null } = params
		return _.get(metaMap, [group, 'list', categoryId ?? index ?? 0, 'fields', name, tierId || 'default'].join('.'))
	}

	static parseValue(value) {
		return (
			{
				boolean: value ? '1' : '0',
				number: value?.toString(),
			}[typeof value] || value
		)
	}

	get isEmpty() {
		return super.isEmpty || typeof this.data.meta_value === 'undefined'
	}

	get hasMetaValue() {
		const { metaValue } = this
		return typeof metaValue !== 'undefined' && this.metaValue !== null && this.metaValue !== ''
	}

	get isTemporary() {
		return !!this.data?.temporaryId
	}

	get isAssetable() {
		return [
			PageMetaData.FIELD_TYPE.IMAGE,
			PageMetaData.FIELD_TYPE.BG_IMAGE,
			PageMetaData.FIELD_TYPE.ASSET,
		].includes(this.config.type)
	}

	get isColor() {
		return [
			PageMetaData.FIELD_TYPE.BORDER_COLOR,
			PageMetaData.FIELD_TYPE.COLOUR,
			PageMetaData.FIELD_TYPE.THEME_COLOUR,
			PageMetaData.FIELD_TYPE.TEXT_COLOR,
		].includes(this.config.type)
	}

	get repeatable() {
		return !!this.data?.repeatable_group
	}

	get locked() {
		return !!this.data?.locked
	}

	set locked(value) {
		if (value) {
			this.fill({
				value: this.metaValue ?? '',
				asset: this.isAssetable ? this.asset : undefined,
				locked: true,
			})
			return
		}

		this.metaValue = this.data.meta_value
		this.asset = this.data.asset
	}

	get pageId() {
		return this.data?.landing_page_id
	}

	get index() {
		return this.data?.meta_key_index
	}

	get categoryId() {
		return this.data?.category_id
	}

	get sectionId() {
		return this.data?.landing_page_section_id
	}

	get tierId() {
		return this.data ? this.data.score_tier_id : this.#fallbackTierId
	}

	get tierKey() {
		return this.tierId ?? 'default'
	}

	get metaKey() {
		return this.data?.meta_key || this.#name
	}

	get repeatableGroup() {
		return this.data?.repeatable_group
	}

	get defaultConfigValue() {
		if (!this.config) return undefined
		const { fallback, default: defaultValue, translation, theme } = this.config

		if (theme?.default) return _.get(this.themeData, [theme.group, theme.default])

		if (!theme && fallback) return _.get(this.scorecard, fallback)

		return (translation && this.#translate?.(translation)) || defaultValue
	}

	get defaultValue() {
		if (this.overriddenMetaData) {
			return this.overriddenMetaData.metaValue
		}
		const value = this.defaultConfigValue

		return value !== null && typeof value === 'object' ? undefined : value
	}

	get defaultAsset() {
		if (this.overriddenMetaData) {
			return this.overriddenMetaData.asset
		}
		const value = this.defaultConfigValue

		return value !== null && typeof value === 'object' ? value : undefined
	}

	get isOverridden() {
		return !!this.overriddenMetaData && !this.isEmpty
	}

	get initialMetaValue() {
		if (this.defaultAsset) {
			return this.defaultAsset.path
		}

		return this.constructor.parseValue(this.defaultValue)
	}

	get metaValue() {
		if (this.isEmpty) {
			return this.initialMetaValue
		}
		const { meta_value: metaValue, theme } = this.data

		return theme ? _.get(this.themeData, theme) : metaValue
	}

	set metaValue(value) {
		if (this.isAssetable) {
			return
		}

		const parsedValue = this.constructor.parseValue(value)

		if (this.isOverridden) {
			this.fill({
				meta_value: parsedValue ?? '',
				theme: null,
			})
		} else {
			const isDefaultValue =
				!this.config?.theme && (typeof parsedValue === 'undefined' || parsedValue === this.initialMetaValue)

			this.fill({
				meta_value: isDefaultValue ? undefined : parsedValue,
				theme: null,
			})
		}
	}

	get asset() {
		if (!this.isAssetable) {
			return undefined
		}

		if (this.isEmpty) {
			return this.defaultAsset
		}

		const { asset, theme } = this.data

		return theme ? _.get(this.themeData, theme) : asset
	}

	set asset(value) {
		if (!this.isAssetable || !value) {
			return
		}

		const { defaultAsset } = this
		const defaultAssetId = defaultAsset && defaultAsset.id

		if (this.isOverridden || this.config?.theme || !defaultAssetId || value.id !== defaultAssetId) {
			this.fill({
				asset: value,
				meta_value: value.path,
				theme: null,
			})

			return
		}

		this.fill({
			asset: null,
			meta_value: undefined,
			theme: null,
		})
	}

	get alt() {
		return this.asset?.alt
	}

	get clearable() {
		if (
			this.config.type === this.constructor.FIELD_TYPE.DROPDOWN &&
			this.config.clearable === false &&
			!this.isOverridden
		) {
			return false
		}
		return !this.isEmpty && this.initialMetaValue !== this.metaValue
	}

	toNumber() {
		return Number(this.metaValue || 0)
	}

	toBoolean() {
		return !!this.toNumber()
	}

	get fieldKey() {
		return this.isOverridden &&
			[PageMetaData.FIELD_TYPE.WYSIWIG, PageMetaData.FIELD_TYPE.WYSIWIG_BASIC].includes(this.config?.type)
			? `overridden-${this.tierId}`
			: `default-${this.tierId}`
	}

	transformMetaValue(shortcuts) {
		const value = this.metaValue

		if (!value) return null

		const matcher = new RegExp(`{(${_.keys(shortcuts).join('|')})}`, 'gi')

		return _.replace(value, matcher, (match, shortcut) => {
			return shortcuts[shortcut]
		})
	}

	get image() {
		return this.preview || (this.asset ? this.asset.path : this.metaValue)
	}

	get imageThumb() {
		const url = this.image
		const { thumbWidth } = this.config || {}

		return this.constructor.imageThumbUrlFrom(url, thumbWidth)
	}

	get imageSrcSet() {
		const url = this.image
		const withThumb = this.pageType !== PageData.TYPES.RESULT_PDF
		const { srcSet, thumbWidth } = this.config || {}

		if (import.meta.env.VITE_CLOUDFLARE_IMAGE_RESIZE === 'false' || !withThumb || !url || (!srcSet && !thumbWidth))
			return null
		const regex = /\.(svg|unknown)$/g

		return !regex.test(url)
			? Object.entries(srcSet || (thumbWidth && { none: thumbWidth }) || {}).map(([key, value]) => ({
					value,
					url,
					key,
			  }))
			: null
	}

	get backgroundImage() {
		return this.image ? `url("${this.imageThumb}")` : null
	}

	backgroundMediaFrom(theme, initial = {}) {
		if (this.#isBuilderMode) {
			return initial
		}
		return _.reduce(
			this.imageSrcSet,
			(acc, { key, url, value }) => {
				const breakpoint = key === 'none' ? '@media all' : theme.breakpoints.down(key)
				const media = breakpoint.replace('@media ', '')

				if (key !== 'none') {
					acc[breakpoint] = {
						...(acc[breakpoint] || {}),
						backgroundImage: `url(${this.constructor.imageThumbUrlFrom(url, value)})`,
					}
				}
				this.constructor.RESOLUTION_SIZES.slice()
					.reverse()
					.forEach(size => {
						const imageResolutionSize = this.constructor.imageResolutionSizeFrom(breakpoint, size, media)

						acc[imageResolutionSize] = {
							...(acc[imageResolutionSize] || {}),
							backgroundImage: `url(${this.constructor.imageThumbUrlFrom(
								url,
								Math.round(value * size),
							)})`,
						}
					})

				return acc
			},
			initial,
		)
	}

	contentDirection(fallbackAlignment = this.constructor.ALIGNMENT.RIGHT) {
		return this.constructor.CONTENT_DIRECTION[this.metaValue || fallbackAlignment] || null
	}

	contentReverseDirection(fallbackAlignment = this.constructor.ALIGNMENT.RIGHT) {
		return this.constructor.CONTENT_REVERSE_DIRECTIONS[this.metaValue || fallbackAlignment]
	}

	contentAlignment(fallbackAlignment = this.constructor.ALIGNMENT.LEFT) {
		return this.constructor.CONTENT_ALIGNMENT[this.metaValue || fallbackAlignment] || null
	}

	get isRightAlignment() {
		return this.metaValue === this.constructor.ALIGNMENT.RIGHT
	}

	get isLeftAlignment() {
		return this.metaValue === this.constructor.ALIGNMENT.LEFT
	}

	get isCenterAlignment() {
		return this.metaValue === this.constructor.ALIGNMENT.CENTER
	}

	get textAlignment() {
		return this.constructor.TEXT_ALIGNMENT[this.metaValue]
	}

	get isBackgroundImage() {
		return this.metaValue === this.constructor.IMAGE_POSITION.BACKGROUND
	}

	get isCenterImage() {
		return this.metaValue === this.constructor.IMAGE_POSITION.CENTER
	}

	get isWide() {
		return this.metaValue === this.constructor.BUTTON_SIZE.WIDE
	}

	get themeSectionKey() {
		return this.#themeSectionKey
	}

	get defaultTheme() {
		const { theme } = this.config || {}
		return theme?.default && _.join([theme.group, theme.default], '.')
	}

	get theme() {
		if (this.overriddenMetaData && this.isEmpty) {
			return this.overriddenMetaData.theme
		}
		return this.isEmpty ? this.defaultTheme : this.data?.theme
	}

	get preview() {
		if (!this.isAssetable) {
			return undefined
		}

		if (this.#preview) {
			return this.#preview
		}

		const { theme } = this

		if (!this.#themeGallery || !theme) {
			return undefined
		}

		const { group, field, preview } = this.#themeGallery

		return theme === _.join([group, field], '.') ? preview : undefined
	}
}

export default PageMetaData
