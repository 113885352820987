import React, { memo, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Switch, Route, Redirect, useLocation } from 'react-router-dom'
import Landing from '@v4/components/pages/Landing'
import Questions from '@v4/components/pages/Questions'
import Results from '@v4/components/pages/Results'
import ResultsPDF from '@v4/components/pages/ResultsPDF'
import Start from '@v4/components/pages/Start'
import ROUTES from '@v4/constants/route'
import AppStore from '@v4/store/appStore'
import PageBuilderSelector from '@v4/utils/selectors/PageBuilderSelector'
import PageData from '@v4/utils/data/PageData'

const ROUTES_PATH = {
	[PageData.TYPES.LANDING]: ROUTES.LANDING,
	[PageData.TYPES.QUESTION]: ROUTES.QUESTIONS,
	[PageData.TYPES.RESULT]: ROUTES.RESULTS,
	[PageData.TYPES.RESULT_PDF]: ROUTES.RESULTS_PDF,
}

const Routes = ({ type }) => {
	const isScorecardMode = AppStore.useStoreState(state => state.general.isScorecardMode)
	const Selector = type ? PageBuilderSelector : null
	const slug = Selector?.usePageSlug()
	const location = useLocation()
	const curLocation = useMemo(() => (isScorecardMode ? location : { pathname: ROUTES_PATH[type] }), [
		isScorecardMode,
		location,
		type,
	])
	const questionId = AppStore.useStoreState(state => state.question.data?.id)

	useEffect(() => {
		if (window === window.parent || document.body.classList.contains('api')) {
			return undefined
		}

		document.body.classList.add('embedded')

		let timeout = null
		let timeout2 = null

		const handleResize = () => {
			clearTimeout(timeout)
			clearTimeout(timeout2)
			const callback = () => {
				const pageType = window.initialData.page?.type
				document.body.style.height = PageData.isTypeQuestions(pageType) ? '600px' : '0px'
				document.body.style.flexGrow = '0'

				window.parent.postMessage(
					{
						scoreapp: {
							height: document.body.scrollHeight,
							type: pageType,
						},
					},
					'*',
				)
				document.body.style.height = null
				document.body.style.flexGrow = null
				document.body.style.width = null
			}
			timeout = setTimeout(() => {
				callback()
				if (type === PageData.TYPES.QUESTION) {
					timeout2 = setTimeout(() => {
						if (type === PageData.TYPES.QUESTION) {
							callback()
						}
					}, 100)
				}
			}, 0)
		}

		handleResize()

		window.addEventListener('resize', handleResize)
		window.addEventListener('lazy-loaded', handleResize)
		window.addEventListener('header-updated', handleResize)

		return () => {
			clearTimeout(timeout)
			clearTimeout(timeout2)
			window.removeEventListener('resize', handleResize)
			window.removeEventListener('lazy-loaded', handleResize)
			window.removeEventListener('header-updated', handleResize)
		}
	}, [type, questionId])

	return (
		<Switch location={curLocation}>
			<Route path={ROUTES.START} component={Start} />
			<Route path={[ROUTES.LANDING, `/p${slug}`]} exact component={Landing} />
			<Route path={ROUTES.QUESTIONS} component={Questions} />
			<Route path={ROUTES.RESULTS_PDF} exact component={ResultsPDF} />
			<Route path={[ROUTES.RESULTS, `${ROUTES.RESULTS}${slug}`]} exact component={Results} />
			<Redirect to={ROUTES.LANDING} />
		</Switch>
	)
}

Routes.propTypes = {
	type: PropTypes.oneOf(Object.values(PageData.TYPES)),
}

Routes.defaultProps = {
	type: window.initialData.page?.type,
}

export default memo(Routes)
