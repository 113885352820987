import { createContextStore } from 'easy-peasy'
import question from './models/question'
import result from './models/result'
import shortcuts from './models/shortcuts'
import signUp from './models/signUp'
import general from './models/general'
import ui from './models/ui'

const AppStore = createContextStore(
	{
		general,
		ui,
		question,
		result,
		shortcuts,
		signUp,
		tokens: { ...(window.initialData.tokens || {}), sasid: window.initialData.sasid || null },
		scoreTiers: window.initialData.score_tiers || [],
	},
	{ name: 'AppStore' },
)

export default AppStore
