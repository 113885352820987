const QUESTION_TYPE = {
	CHECKBOX: 'checkbox',
	COUNTRY: 'country',
	HIDDEN: 'hidden',
	TEXT: 'text',
	INDUSTRY: 'industry',
	DROPDOWN: 'dropdown',
	PHONE: 'phone',
	OTHER: 'other',
}

export const OPT_IN = {
	IMPLIED_CONSENT: 1,
	EXPLICIT_CONSENT_OPTIONAL: 2,
	EXPLICIT_CONSENT_REQUIRED: 3,
	OPTIN_NONE: 4,
}

export const QUESTION_REL_KEY = {
	[QUESTION_TYPE.COUNTRY]: QUESTION_TYPE.OTHER,
	[QUESTION_TYPE.INDUSTRY]: QUESTION_TYPE.OTHER,
	[QUESTION_TYPE.DROPDOWN]: QUESTION_TYPE.OTHER,
	[QUESTION_TYPE.CHECKBOX]: QUESTION_TYPE.CHECKBOX,
	[QUESTION_TYPE.TEXT]: QUESTION_TYPE.OTHER,
	[QUESTION_TYPE.HIDDEN]: QUESTION_TYPE.HIDDEN,
	[QUESTION_TYPE.PHONE]: QUESTION_TYPE.OTHER,
}

export default QUESTION_TYPE
