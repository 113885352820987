import PageSectionData from '../data/PageSectionData'
import PageTemplateData from '../data/PageTemplateData'
import PageBuilderSelector from '../selectors/PageBuilderSelector'

class PageSectionCollection {
	#data

	static getUniqueId(entity) {
		return entity.temporaryId || entity.id
	}

	static get EntityData() {
		return PageSectionData
	}

	constructor(data) {
		this.#data = data
	}

	get data() {
		return this.#data
	}

	get collections() {
		return this.data.collections
	}

	set collections(value) {
		this.data.collections = value
	}

	get entities() {
		return this.data.entities
	}

	set entities(value) {
		this.data.entities = value
	}

	entityDataFrom(id) {
		return new this.constructor.EntityData(this.entities[id])
	}

	static create(list, updateEntity, template) {
		const data = _.reduce(
			list,
			(acc, entity, index) => {
				const id = this.getUniqueId(entity)

				const sectionTemplate = new PageBuilderSelector({ template }).selectSectionTemplate(entity.key)

				if (!sectionTemplate) {
					return acc
				}
				const sortGroup = sectionTemplate.sort_group

				acc.collections[sortGroup || PageTemplateData.SORT_GROUPS.MAIN].push(id)
				acc.entities[id] = updateEntity ? updateEntity(entity, index) : entity

				return acc
			},
			{
				collections: {
					[PageTemplateData.SORT_GROUPS.POPUP]: [],
					[PageTemplateData.SORT_GROUPS.HEADER]: [],
					[PageTemplateData.SORT_GROUPS.MAIN]: [],
					[PageTemplateData.SORT_GROUPS.FOOTER]: [],
				},
				entities: {},
			},
		)

		return new this(data)
	}

	add(entity, sortGroup, index) {
		const id = this.constructor.getUniqueId(entity)
		this.entities[id] = entity

		this.collections[sortGroup].push(id)
		if (typeof index === 'number') {
			this.collections[sortGroup].splice(index, 0, id)
		} else {
			this.collections[sortGroup].push(id)
		}
		this.collections[sortGroup] = _.uniq(this.collections[sortGroup])

		return this
	}

	remove(id, sortGroup) {
		this.collections[sortGroup] = _.filter(this.collections[sortGroup], entityId => entityId !== id)
		delete this.entities[id]

		return this
	}

	update(ids, sortGroup) {
		this.collections[sortGroup] = ids
	}
}

export default PageSectionCollection
