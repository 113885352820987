export const EMAIL_DELIVERABILITY_STATUS = Object.freeze({
	DELIVERABLE: 'DELIVERABLE',
	UNDELIVERABLE: 'UNDELIVERABLE',
	UNKNOWN: 'UNKNOWN',
})

export const EMAIL_DELIVERABILITY_OPTIONS = Object.freeze({
	NOT_CHECK: 'not_check',
	BLOCK_UNDELIVERABLE: 'block_undeliverable',
	BLOCK_UNDELIVERABLE_AND_UNKNOWN: 'block_undeliverable_and_unknown',
})
