import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import useStyles from './Denominator.styled'

const Denominator = props => {
	const classes = useStyles(props)
	const { percent, className } = props

	return (
		<span className={clsx(className, classes.root, 'denominator')}>
			{Math.floor(percent / 10)}
			<span className="denominator-small">/10</span>
		</span>
	)
}

Denominator.propTypes = {
	percent: PropTypes.number.isRequired,
	className: PropTypes.string,
}

Denominator.defaulrProps = {
	className: '',
}

export default Denominator
