import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import Bugsnag from '@bugsnag/js'
import { isChunkLoadingError } from '@v4/utils/browser'
import withStyles from './ErrorBoundary.styled'

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			hasError: false,
			chunkLoadError: false,
			chunkErrorsCount: +(sessionStorage.getItem('chunkErrorsCount') || 0),
		}
	}

	componentDidCatch(error) {
		const { chunkErrorsCount } = this.state

		const chunkLoadError = isChunkLoadingError(error)

		if (chunkLoadError) {
			if (chunkErrorsCount < 3) {
				sessionStorage.setItem('chunkErrorsCount', chunkErrorsCount + 1)
				window.location.reload()
			}
		} else {
			Bugsnag.notify(error, event => {
				// eslint-disable-next-line no-param-reassign
				event.severity = 'error'
			})
		}

		this.setState({
			hasError: true,
			chunkLoadError,
		})
	}

	render() {
		const { hasError, chunkLoadError } = this.state
		const { children, classes } = this.props
		const { chunkErrorsCount } = this.state

		if (chunkLoadError) {
			return chunkErrorsCount < 3 ? null : (
				<div className={classes.root}>
					<h1>
						Your browser is trying to access an older version of ScoreApp.
						<br />
						Please refresh your browser. If the problem persists try clearing your cache.
					</h1>
				</div>
			)
		}
		if (hasError) {
			return (
				<div className={classes.root}>
					<h1>Something went wrong.</h1>
				</div>
			)
		}

		return children
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	location: PropTypes.object.isRequired,
	classes: PropTypes.object.isRequired,
}

export default withStyles(withRouter(ErrorBoundary))
