// eslint-disable-next-line import/prefer-default-export
export const PALETTE_TEXT_VARIANTS = {
	PAPER_TEXT: 'paperText',
	TEXT: 'text',
}

export const LUMINANCE_BOUNDARY = 0.5

export const THEME_RELATION_TYPE = Object.freeze({
	SCORECARD: 'scorecard',
})

export const THEME_FIELD_TYPE = Object.freeze({
	ASSET: 'asset',
	COLOR: 'color',
	FONT: 'font',
})
