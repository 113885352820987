import { nanoid } from 'nanoid'

class BaseData {
	#data

	#originData

	constructor(data = null) {
		this.#data = data
		this.#originData = data
	}

	get data() {
		return this.#data
	}

	get isEmpty() {
		return _.isEmpty(this.#data)
	}

	static get initialData() {
		return {}
	}

	static generateTemporaryId = () => `temporary_${nanoid()}`

	fill(values) {
		this.#data = _.reduce(
			values,
			(acc, value, key) => {
				acc[key] = value

				return acc
			},
			this.#data ? { ...this.#data } : this.constructor.initialData,
		)

		return this
	}

	save() {
		if (!this.#data) {
			return this
		}

		this.#data = _.reduce(
			this.#data,
			(acc, value, key) => {
				acc[key] = value

				return acc
			},
			this.#originData || {},
		)

		this.#originData = this.#data

		return this
	}

	saveTo(parent, path) {
		_.set(parent, path, this.save().data)

		return this
	}

	clone() {
		return new this.constructor(this.data)
	}
}

export default BaseData
