import i18next from 'i18next'
import ChainedBackend from 'i18next-chained-backend'
import { initReactI18next } from 'react-i18next'
import { I18N_CONFIG } from '@v4/constants/i18n'

const i18nextInstance = i18next
	.createInstance()
	.use(ChainedBackend)
	.use(initReactI18next)

i18nextInstance.init({
	...I18N_CONFIG,
	lng: window.initialData.scorecard?.settings?.locale || 'en',
})

export default i18nextInstance
